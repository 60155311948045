var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pt-0", attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "", id: "form" },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12 text-left" } }, [
                _c("p", { staticClass: "mb-0" }, [
                  _vm._v("* Campi obbligatori"),
                ]),
              ]),
              _c(
                "v-col",
                { staticClass: "text-left", attrs: { cols: "12", sm: "6" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", id: "name" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "pink title text-uppercase py-1 px-2",
                            },
                            [_vm._v("Tu sei")]
                          ),
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "scroll-to",
                                rawName: "v-scroll-to",
                                value: "#name",
                                expression: "'#name'",
                              },
                            ],
                            attrs: {
                              rules: _vm.rules.name,
                              label: "Nome*",
                              color: "AXABlue",
                              required: "",
                            },
                            model: {
                              value: _vm.name,
                              callback: function ($$v) {
                                _vm.name = $$v
                              },
                              expression: "name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", id: "date" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "scroll-to",
                                rawName: "v-scroll-to",
                                value: "#date",
                                expression: "'#date'",
                              },
                            ],
                            attrs: {
                              maxlength: "10",
                              id: "dateInput",
                              color: "AXABlue",
                              label: "Data di nascita*",
                              placeholder: "GG/MM/AAAA",
                              rules: _vm.rules.date,
                              type: "text",
                            },
                            on: {
                              keyup: function ($event) {
                                _vm.date = _vm.formatDate(_vm.date)
                              },
                            },
                            model: {
                              value: _vm.date,
                              callback: function ($$v) {
                                _vm.date = $$v
                              },
                              expression: "date",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "text-left", attrs: { cols: "12", sm: "6" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", id: "namePartner" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "pink title text-uppercase py-1 px-2",
                            },
                            [_vm._v(_vm._s(_vm.getInsTypeData.labelPartner))]
                          ),
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "scroll-to",
                                rawName: "v-scroll-to",
                                value: "#namePartner",
                                expression: "'#namePartner'",
                              },
                            ],
                            attrs: {
                              rules: _vm.rules.name,
                              label: "Nome*",
                              color: "AXABlue",
                              required: "",
                            },
                            model: {
                              value: _vm.namePartner,
                              callback: function ($$v) {
                                _vm.namePartner = $$v
                              },
                              expression: "namePartner",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", id: "datePartner" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "scroll-to",
                                rawName: "v-scroll-to",
                                value: "#datePartner",
                                expression: "'#datePartner'",
                              },
                            ],
                            attrs: {
                              maxlength: "10",
                              id: "dateInputPartner",
                              color: "AXABlue",
                              label: "Data di nascita*",
                              placeholder: "GG/MM/AAAA",
                              rules: _vm.rules.date,
                              type: "text",
                            },
                            on: {
                              keyup: function ($event) {
                                _vm.datePartner = _vm.formatDate(
                                  _vm.datePartner
                                )
                              },
                            },
                            model: {
                              value: _vm.datePartner,
                              callback: function ($$v) {
                                _vm.datePartner = $$v
                              },
                              expression: "datePartner",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-row"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }