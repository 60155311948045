var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "py-0 contentTop", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            {
              staticClass: "hero-banner",
              attrs: { align: "center", justify: "center" },
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", xl: "9" } },
                [
                  _c(
                    "v-container",
                    { staticClass: "hero-banner__inner text-center" },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center", justify: "center" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 order-lg-last offset-lg-1",
                              attrs: {
                                cols: "12",
                                lg: "6",
                                "data-aos": "fade-zoom-in",
                                "data-aos-easing": "ease",
                                "data-aos-duration": "500",
                                "data-aos-delay": "500",
                              },
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  src: require("@/assets/images/home/home-illustrazione-01@2x.png"),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "caption text-center text-lg-left",
                              attrs: {
                                cols: "12",
                                lg: "5",
                                "data-aos": "fade-zoom-in",
                                "data-aos-easing": "ease",
                                "data-aos-duration": "500",
                                "data-aos-delay": "500",
                              },
                            },
                            [
                              _c("h2", { staticClass: "display-2" }, [
                                _vm._v("La polizza vita semplice, "),
                                _c("br", { staticClass: "d-block d-md-none" }),
                                _vm._v("dedicata "),
                                _c("br", {
                                  staticClass: "d-none d-md-block d-lg-none",
                                }),
                                _vm._v("alle coppie di ogni tipo, nella vita "),
                                _c("br", {
                                  staticClass: "d-none d-md-block d-lg-none",
                                }),
                                _vm._v("o professionali."),
                              ]),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "title font-weight-regular my-8 mt-md-4 my-lg-8 small",
                                },
                                [
                                  _vm._v(
                                    " Basta scegliere il capitale che si desidera assicurare per proteggere "
                                  ),
                                  _c("br", {
                                    staticClass: "d-none d-md-block d-lg-none",
                                  }),
                                  _vm._v(
                                    "due persone e coloro a cui sono legate, in caso di grandi imprevisti. "
                                  ),
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "pinkDark white--text",
                                  attrs: {
                                    "x-large": "",
                                    tile: "",
                                    elevation: "0",
                                    "data-aos": "fade-zoom-in",
                                    "data-aos-easing": "ease",
                                    "data-aos-duration": "500",
                                    "data-aos-delay": "500",
                                  },
                                  on: { click: _vm.tracciamento },
                                },
                                [_vm._v(" Calcola una quotazione ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0 scroll-down-bg", attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "fill-height text-center mx-0",
                      attrs: { align: "center", justify: "center" },
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-avatar",
                            {
                              staticClass: "scale-animation",
                              attrs: { width: "100", height: "100" },
                              on: {
                                click: function ($event) {
                                  return _vm.$vuetify.goTo(
                                    ".contentBottom",
                                    500
                                  )
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  width: "100",
                                  height: "100",
                                  src: require("@/assets/images/home/cursore.svg"),
                                  alt: "scroll down",
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "py-0 contentBottom", attrs: { fluid: "" } },
        [
          _c(
            "v-container",
            { staticClass: "py-0" },
            [
              _c(
                "v-row",
                {
                  staticClass: "pb-12",
                  attrs: { align: "center", justify: "center" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "text-center mt-sm-12 mt-md-10 mt-lg-12 pb-0",
                      attrs: { cols: "12", xl: "9" },
                    },
                    [
                      _c("h2", { staticClass: "display-2 title" }, [
                        _vm._v("Come funziona"),
                      ]),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0 px-0",
                      attrs: { cols: "12", xl: "9" },
                    },
                    [_c("Infografica")],
                    1
                  ),
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 text-center mx-auto",
                              attrs: { cols: "12", xl: "9" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "pinkDark white--text",
                                  attrs: {
                                    "x-large": "",
                                    tile: "",
                                    elevation: "0",
                                    "data-aos": "fade-zoom-in",
                                    "data-aos-easing": "ease",
                                    "data-aos-duration": "500",
                                    "data-aos-delay": "200",
                                  },
                                  on: { click: _vm.tracciamento },
                                },
                                [_vm._v(" Calcola una quotazione ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "bg-white pt-3",
              attrs: { align: "start", justify: "center" },
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "text-center pb-0",
                  attrs: { cols: "12", xl: "9" },
                },
                [
                  _c("h2", { staticClass: "display-2" }, [
                    _vm._v("Perchè sceglierla"),
                  ]),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12", xl: "9" } },
                [
                  _c("CarouselHome", {
                    attrs: {
                      id: "carousel_home",
                      "data-aos": "fade-up",
                      "data-aos-easing": "ease",
                      "data-aos-duration": "500",
                      "data-aos-delay": "600",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "bg-white pt-12",
              attrs: { align: "start", justify: "center" },
            },
            [
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "12", xl: "9" } },
                [
                  _c("h2", { staticClass: "display-2" }, [
                    _vm._v("Domande frequenti"),
                  ]),
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass: "py-0",
                  attrs: { cols: "12", lg: "8", xl: "7" },
                },
                [
                  _c("faqDropdown", {
                    attrs: {
                      id: "faq",
                      "data-aos": "fade-up",
                      "data-aos-easing": "ease",
                      "data-aos-duration": "500",
                      "data-aos-delay": "800",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "bg-white",
              attrs: { align: "start", justify: "center" },
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "text-center my-0 mb-md-4 scroll-top",
                  attrs: { cols: "12" },
                },
                [
                  _c(
                    "v-avatar",
                    {
                      staticClass: "mt-6 rotate180",
                      attrs: { width: "100", height: "100" },
                      on: {
                        click: function ($event) {
                          return _vm.$vuetify.goTo(".v-application", 500)
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          width: "100",
                          height: "100",
                          src: require("@/assets/images/home/cursore.svg"),
                          alt: "scroll top",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }