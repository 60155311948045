<template>
    <div>
        <!-- Top -->
        <v-container fluid class="py-0 contentTop">
            <!-- Hero -->
            <v-row align="center" justify="center" class="hero-banner">
                <v-col cols="12" xl="9">
                    <v-container class="hero-banner__inner text-center">
                        <v-row align="center" justify="center">
                            <v-col cols="12" lg="6" class="pa-0 order-lg-last offset-lg-1"
                                   data-aos="fade-zoom-in"
                                   data-aos-easing="ease"
                                   data-aos-duration="500"
                                   data-aos-delay="500">
                                <v-img src="@/assets/images/home/home-illustrazione-01@2x.png"/>
                            </v-col>
                            <v-col cols="12" lg="5" class="caption text-center text-lg-left"
                                   data-aos="fade-zoom-in"
                                   data-aos-easing="ease"
                                   data-aos-duration="500"
                                   data-aos-delay="500">
                                <h2 class="display-2">La polizza vita semplice,
                                    <br class="d-block d-md-none">dedicata
                                    <br class="d-none d-md-block d-lg-none">alle coppie di ogni tipo, nella vita
                                    <br class="d-none d-md-block d-lg-none">o professionali.</h2>
                                <p class="title font-weight-regular my-8 mt-md-4 my-lg-8 small">
                                    Basta scegliere il capitale che si desidera assicurare per
                                    proteggere <br class="d-none d-md-block d-lg-none">due persone e
                                    coloro a cui sono legate, in caso di grandi imprevisti.
                                </p>
                                <!-- button -->
                                <v-btn x-large tile elevation="0" @click="tracciamento"
                                       class="pinkDark white--text"
                                       data-aos="fade-zoom-in"
                                       data-aos-easing="ease"
                                       data-aos-duration="500"
                                       data-aos-delay="500">
                                    Calcola una quotazione
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
            <!-- Freccia -->
            <v-row>
                <v-col cols="12" class="pa-0 scroll-down-bg">
                    <v-row align="center" justify="center" class="fill-height text-center mx-0">
                        <v-col cols="12" class="pa-0">
                            <v-avatar class="scale-animation" width="100" height="100"
                                      @click="$vuetify.goTo('.contentBottom', 500)">
                                <img width="100" height="100"
                                     src="@/assets/images/home/cursore.svg"
                                     alt="scroll down"
                                >
                            </v-avatar>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
        <!-- Bottom -->
        <v-container fluid class="py-0 contentBottom">
            <v-container class="py-0">
                <!-- Come funziona -->
                <v-row align="center" justify="center" class="pb-12">
                    <v-col cols="12" xl="9" class="text-center mt-sm-12 mt-md-10 mt-lg-12 pb-0">
                        <h2 class="display-2 title">Come funziona</h2>
                    </v-col>
                    <!-- Infografica -->
                    <v-col cols="12" xl="9" class="py-0 px-0">
                        <Infografica/>
                    </v-col>
                    <v-container>
                        <v-row>
                            <v-col cols="12" xl="9" class="py-0 text-center mx-auto">
                                <!-- Button -->
                                <v-btn x-large tile elevation="0" @click="tracciamento"
                                       class="pinkDark white--text"
                                       data-aos="fade-zoom-in"
                                       data-aos-easing="ease"
                                       data-aos-duration="500"
                                       data-aos-delay="200">
                                    Calcola una quotazione
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-row>
            </v-container>
            <!-- Perchè sceglierla -->
            <v-row align="start" justify="center" class="bg-white pt-3">
                <v-col cols="12" xl="9" class="text-center pb-0">
                    <h2 class="display-2">Perchè sceglierla</h2>
                </v-col>
                <!-- Carousel -->
                <v-col cols="12" xl="9" class="py-0">
                    <CarouselHome id="carousel_home"
                                  data-aos="fade-up"
                                  data-aos-easing="ease"
                                  data-aos-duration="500"
                                  data-aos-delay="600"/>
                </v-col>
            </v-row>
            <!-- Domande frequenti -->
            <v-row align="start" justify="center" class="bg-white pt-12">
                <v-col cols="12" xl="9" class="text-center">
                    <h2 class="display-2">Domande frequenti</h2>
                </v-col>
                <v-col cols="12" lg="8" xl="7" class="py-0">
                    <faqDropdown id="faq"
                                 data-aos="fade-up"
                                 data-aos-easing="ease"
                                 data-aos-duration="500"
                                 data-aos-delay="800"/>
                </v-col>
            </v-row>
            <!-- back top -->
            <v-row align="start" justify="center" class="bg-white">
                <v-col cols="12" class="text-center my-0 mb-md-4 scroll-top">
                    <v-avatar class="mt-6 rotate180" width="100" height="100"
                              @click="$vuetify.goTo('.v-application', 500)">
                        <img width="100" height="100"
                             src="@/assets/images/home/cursore.svg"
                             alt="scroll top"
                        >
                    </v-avatar>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Infografica from "@/components/partials/Infografica";
    import CarouselHome from "@/components/partials/CarouselDefault";
    import faqDropdown from "@/components/partials/axa/SelectDropdown_axa";
    import {mapActions} from "vuex";

    export default {
        name: "homeBody",
        components: {
            Infografica,
            CarouselHome,
            faqDropdown
        },
        data() {
            return {
                idSession: sessionStorage.getItem('sessionId'),
            };
        },
        methods: {
            ...mapActions({
                'actionClearQuotation': 'params/actionClearQuotation',
                'actionInsType': 'params/actionInsType',
                'actionCustomerType': 'params/actionCustomerType'
            }),
            tracciamento() {
                window.tc_events_global(this.$el, 'xt_click', {
                    'level_2_number': 'AXA_PerNoi',
                    'click_name': 'Cosa_sogni',
                    'click_type': sessionStorage.getItem('sessionId')
                });
                this.actionClearQuotation();
                this.$router.push('/axa/chi_ami');
            },
        }
    };
</script>
