<template>
    <v-dialog v-model="codeFiscale" max-width="600" class="codiceFiscale" scrollable>
        <template v-slot:activator="{ on }">
            <v-btn large text v-on="on"
                   class="dialog__btn transparent text-lowercase blue--text font-weight-medium pa-0 resetPaddingLeft">
                Non ricordi il tuo Codice Fiscale?
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="dialog__card__title">
                <v-container fill-height>
                    <v-layout row wrap align-center>
                        <v-flex xs9 offset-xs-3>
                            <h4>Il tuo codice fiscale</h4>
                        </v-flex>
                        <v-flex xs3 class="text-right">
                            <v-btn
                                    class="AXABlue--text text-right"
                                    :elevation="0"
                                    fab
                                    small
                                    text
                                    @click="codeFiscale = false">
                                <v-icon dark>clear</v-icon>
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card-title>
            <v-card-text class="px-0 pb-0">
                <v-container class="py-0">
                    <v-row>
                        <v-col>
                            <div class="pt-4 blueDark--text font-weight-bold">*Campi obbligatori</div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="codiceFiscale__form white pl-5 py-0">
                            <v-form ref="codeFisc" v-model="valid" lazy-validation id="formCodiceFiscale">
                                <v-container class="py-0">
                                    <v-layout row>
                                        <v-flex xs12 md6 class="pr-md-6 pb-4">
                                            <v-text-field v-model="nome" label="Nome*" :rules="inputRules"
                                                          required @input="generaCodiceFiscale"></v-text-field>
                                        </v-flex>
                                        <v-flex xs12 md6 class="pr-md-6 pb-4">
                                            <v-text-field v-model="cognome" label="Cognome*"
                                                          :rules="inputRules"
                                                          required @input="generaCodiceFiscale"></v-text-field>
                                        </v-flex>
                                        <v-flex xs12 md6 class="pr-md-6 pb-4">
                                            <v-text-field
                                                    maxlength="10"
                                                    @keyup="data=formatDate(data)"
                                                    label="Data di nascita*"
                                                    placeholder="GG/MM/AAAA"
                                                    :rules="dateRules"
                                                    v-model="data"
                                                    :value="data"
                                                    type="tel"
                                                    v-scroll-to="'#date'"
                                            ></v-text-field>
                                        </v-flex>
                                        <v-flex xs12 md6 class="pr-md-6 pb-4">
                                            <v-autocomplete v-model="comuneSel" :items="comuniIter"
                                                            label="Luogo di nascita"
                                                            @input="generaCodiceFiscale"
                                                            :rules="inputRules"
                                                            :filter="cerca"
                                                            :menu-props="{ maxHeight: 150 }"
                                                            hide-no-data></v-autocomplete>
                                        </v-flex>
                                        <v-flex xs12 md6 class="pr-md-6">
                                            <v-select :items="selectItems" label="Sesso*" v-model="sesso"
                                                      @input="generaCodiceFiscale" :rules="inputRules"
                                            ></v-select>
                                        </v-flex>
                                        <v-flex xs12 class="pr-md-6 py-6 text-center">
                                            <v-btn text height="48" width="420" :disabled="disabled" @click="invia"
                                                   class="prospetto__invia pinkDark white--text font-weight-medium">
                                                Invia
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

    import * as moment from 'moment';
    import {mapGetters} from "vuex";

    export default {
        name: "codiceFiscale",
        components: {},
        computed: {
            ...mapGetters({
                'getQuotation': 'params/getQuotation',
                'getInsuredData': 'params/getInsuredData',
            }),
        },
        watch: {},
        created() {
            // Carica i comuni
            this.ottieniComuniJSON();
            this.cont = 1;
        },
        data: vm => ({
            codeFiscale: false,
            valid: false,
            disabled: true,
            inputRules: [
                v => !!v || 'Campo obbligatorio',
            ],
            nome: '',
            cognome: '',
            data: null,
            dateRules: [
                val => moment(val, "DD/MM/YYYY") >= moment().subtract(60, 'years') && moment(val, "DD/MM/YYYY") <= moment().subtract(18, 'years') || `L'età deve essere compresa tra 18 e 60 anni`,
                val => /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/i.test(val) || 'La data deve avere formato gg/mm/aaaa',
            ],
            selectItems: ["Maschio", "Femmina"],
            sesso: "",
            comuneSel: '',
            comuniObj: {},
            comuniIter: [],
            comuniIterBase: [],
            codiceFiscale: '',
        }),
        methods: {
            // Richiesta HTTP per ottenere i comuni utilizzando il fetch API
            ottieniComuniJSON() {
                let app_get_cities = process.env.VUE_APP_CITIES;
                let _this = this;

                grecaptcha.ready(function () {
                    grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, {action: 'login'}).then(function (captchaToken) {
                        axios.get(app_get_cities, {
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            params:{
                                'captcha': captchaToken
                            }
                        })
                        .then(resp => {
                            console.log('resp.data',resp.data);

                            let data = [];
                            Object.keys(resp.data).forEach(function(key) {
                                data.push({value:key, text:resp.data[key]});
                            });

                            data.sort(function (a, b) {
                                return a.text.localeCompare(b.text);
                            });

                            console.log('comuni',resp.data);
                            console.log('data',data);
                            _this.comuniObj = resp.data;
                            _this.comuniIter = data;
                        })
                    });
                });
                // fetch("https://systool.altervista.org/catCom.php")
                //     .then(resp => {
                //         console.log('resp.json() 1',resp.json());
                //         return resp.json();
                //     })
                //     .then(comuni => {
                //         console.log('comuni 1',comuni);
                //         this.comuniObj = comuni;
                //         this.comuniIter = Object.keys(comuni).sort();
                //     });
            },
            // Elabora il nome o cognome della persona restituendo 3 caratteri.
            elaboraNomeCognome(stringa, tipo) {
                let vocali = [];
                // Estrae le vocali di una stringa e le aggiunge all'array vocali.
                const estraiVocali = stringa =>
                    stringa.replace(/a|e|i|o|u/gi, x => {
                        vocali.push(x);
                        return "";
                    });
                // Es. "Fo" => "FOX"
                if (stringa.length == 1) return `${stringa.toUpperCase()}XX`;
                if (stringa.length == 2) return `${stringa.toUpperCase()}X`;
                // Es. "Bon" => "BNO"
                let sEstratta = estraiVocali(stringa);
                if (sEstratta.length == 1)
                    return `${sEstratta}${vocali[0]}${vocali[1]}`.toUpperCase();
                if (sEstratta.length == 2)
                    return `${sEstratta}${vocali[0]}`.toUpperCase();
                // Es. "Matteo" => "MTT"
                if (sEstratta.length == 3 && tipo == "nome")
                    return `${sEstratta.toUpperCase()}`;
                vocali = [];
                // Es. "Guarda" => "GRD"
                return tipo == "cognome"
                    ? sEstratta.substr(0, 3).toUpperCase()
                    : `${sEstratta[0]}${sEstratta[2]}${sEstratta[3]}`.toUpperCase();
            },
            // Restituisce le 5 cifre che riguardano la data di nascita e il sesso della
            // persona
            elaboraDataESesso(data, sesso) {
                let ris = "";
                // Aggiungi le ultime due cifre dell'anno
                ris += data.substr(2, 2);
                // Aggiungi la cifra per il mese
                if (data.substr(5, 2).match(/0[1-5]/g)) {
                    ris += String.fromCharCode(parseInt(data.substr(5, 2)) + 64);
                } else if (data.substr(5, 2) == "06") {
                    ris += "H";
                } else if (data.substr(5, 2) == "09") {
                    ris += "P";
                } else if (data.substr(5, 2).match(/0[7-8]/g)) {
                    ris += String.fromCharCode(parseInt(data.substr(5, 2)) + 69);
                } else if (data.substr(5, 2).match(/1[0-2]/g)) {
                    ris += String.fromCharCode(parseInt(data.substr(5, 2)) + 72);
                }
                // Aggiungi il giorno
                ris +=
                    sesso.toLowerCase() == "maschio"
                        ? `${data.substr(8, 2)}`
                        : `${parseInt(data.substr(8, 2)) + 40}`;
                return ris;
            },
            // Calcola il carattere di controllo (CIN, Control Internal Number)
            calcolaCIN(codice) {
                let addendi = [];
                for (let i = 0; i < codice.length; i++) {
                    if (i % 2 != 0) {
                        addendi.push(
                            codice[i].match(/[0-9]/g) ? codice[i] : codice.charCodeAt(i) - 65
                        );
                    } else {
                        switch (codice[i]) {
                            case "0":
                            case "A":
                                addendi.push("1");
                                break;
                            case "1":
                            case "B":
                                addendi.push("0");
                                break;
                            case "2":
                            case "C":
                                addendi.push("5");
                                break;
                            case "3":
                            case "D":
                                addendi.push("7");
                                break;
                            case "4":
                            case "E":
                                addendi.push("9");
                                break;
                            case "5":
                            case "F":
                                addendi.push("13");
                                break;
                            case "6":
                            case "G":
                                addendi.push("15");
                                break;
                            case "7":
                            case "H":
                                addendi.push("17");
                                break;
                            case "8":
                            case "I":
                                addendi.push("19");
                                break;
                            case "9":
                            case "J":
                                addendi.push("21");
                                break;
                            case "K":
                                addendi.push("2");
                                break;
                            case "L":
                                addendi.push("4");
                                break;
                            case "M":
                                addendi.push("18");
                                break;
                            case "N":
                                addendi.push("20");
                                break;
                            case "O":
                                addendi.push("11");
                                break;
                            case "P":
                                addendi.push("3");
                                break;
                            case "Q":
                                addendi.push("6");
                                break;
                            case "R":
                                addendi.push("8");
                                break;
                            case "S":
                                addendi.push("12");
                                break;
                            case "T":
                                addendi.push("14");
                                break;
                            case "U":
                                addendi.push("16");
                                break;
                            case "V":
                                addendi.push("10");
                                break;
                            case "W":
                                addendi.push("22");
                                break;
                            case "X":
                                addendi.push("25");
                                break;
                            case "Y":
                                addendi.push("24");
                                break;
                            case "Z":
                                addendi.push("23");
                                break;
                        }
                    }
                }
                return String.fromCharCode(
                    (addendi.reduce((a, b) => parseInt(a) + parseInt(b)) % 26) + 65
                );
            },
            // Genera il codice fiscale
            generaCodiceFiscale() {
                // 3 cifre, cognome
                this.codiceFiscale =
                    this.cognome === ""
                        ? ""
                        : this.elaboraNomeCognome(this.cognome.replace(/\s+/g, ''), "cognome");
                // 3 cifre, nome
                this.codiceFiscale +=
                    this.nome === "" ? "" : this.elaboraNomeCognome(this.nome.replace(/\s+/g, ''), "nome");
                // 5 cifre, nascita e sesso
                this.codiceFiscale +=
                    this.data === null || this.sesso === ""
                        ? ""
                        : this.elaboraDataESesso(this.data.split("/").reverse().join("-"), this.sesso);
                // 4 cifre, codice catastale
                console.log(this.comuneSel);
                this.codiceFiscale +=
                    this.comuneSel === "" ? "" : this.comuneSel;
                    // this.comuneSel === "" ? "" : this.comuniObj[this.comuneSel];
                // 1 cifra, CIN
                this.codiceFiscale +=
                    this.cognome != "" &&
                    this.nome != "" &&
                    this.data != null &&
                    this.sesso != ""
                        ? this.calcolaCIN(this.codiceFiscale)
                        : "";
            },
            invia() {
                if (this.$refs.codeFisc.validate()) {
                    this.valid = true;
                    this.$emit('update', this.codiceFiscale);
                    this.codeFiscale = false;
                } else {
                    this.valid = false;
                    this.disabled = true;
                }
            },
            cerca(item, queryText, itemText) {
                if (queryText.length >= 2) {
                    return itemText.toLocaleLowerCase().startsWith(queryText.toLocaleLowerCase());
                }
            },
            hideComuni() {
                this.comuniIterBase = this.comuniIter;
                this.comuniIter = [];
            },
            showComuni() {
                this.comuniIter = this.comuniIterBase;
            },
            formatDate: function (value) {
                value = value.replace(/\D/g, "")
                    .split(/(..)/)
                    .filter(s => s.length > 0)
                    .reduce((t, v, i, a) => t + v + (i > 1 || i == a.length - 1 ? '' : '/'), '');
                return value;
            }
        },
        mounted() {
            //this.nome = this.getQuotation.firstInsName;
            this.nome = this.getInsuredData.name;
            this.data = this.getInsuredData.date.substr(0, 10);

            //  console.log(this.formatDate(this.getQuotation.firstInsBd.substr(0, 10)));
            //  this.data = this.getQuotation.firstInsBd.substr(0, 10);
        },
        updated() {
            if (this.nome != '' && this.cognome != '' && this.data != '' && this.sesso != '' && this.comuneSel != '' && this.valid) {
                this.disabled = false;
            } else {
                this.disabled = true;
            }
        }
    };
</script>
