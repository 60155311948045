var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "waypoint",
          rawName: "v-waypoint",
          value: {
            active: true,
            callback: _vm.onWaypoint,
            options: _vm.intersectionOptions,
          },
          expression:
            "{ active: true, callback: onWaypoint, options: intersectionOptions }",
        },
      ],
      staticClass: "stickyContainer",
    },
    [
      _c(
        "v-layout",
        {
          class: { isFixed: _vm.addFixed },
          attrs: { id: "sticky_desktop", elevation: "5" },
        },
        [
          _c(
            "v-overlay",
            {
              model: {
                value: _vm.overlay,
                callback: function ($$v) {
                  _vm.overlay = $$v
                },
                expression: "overlay",
              },
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", size: "64" },
              }),
            ],
            1
          ),
          _c(
            "v-card",
            {
              staticClass: "stickyBar",
              class: [_vm.goBack == "assicurati_mps" ? "d-none" : ""],
              attrs: { width: "100%", elevation: "5" },
            },
            [
              _c(
                "v-container",
                { staticClass: "py-0", attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center", justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "8", md: "6", lg: "4" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "center", justify: "center" } },
                            [
                              _c(
                                "v-col",
                                {
                                  class: [
                                    "py-0 order-1",
                                    _vm.showSkip
                                      ? "col-6"
                                      : "col-5 text-center",
                                  ],
                                  attrs: { cols: "5" },
                                },
                                [
                                  _vm.goBack
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "btn_goBack AXABlue--text",
                                              attrs: { text: "" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.btnActions(
                                                    _vm.goTo,
                                                    _vm.goBack,
                                                    _vm.isNext,
                                                    true,
                                                    _vm.parentPage
                                                  ),
                                                    _vm.hitTrack(
                                                      _vm.level_2_number,
                                                      _vm.click_name,
                                                      _vm.click_type
                                                    )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "AXABlue--text",
                                                  attrs: {
                                                    left: "",
                                                    small: "",
                                                  },
                                                },
                                                [_vm._v("arrow_back")]
                                              ),
                                              _vm._v(" Indietro "),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-center py-0 order-2",
                                  attrs: { cols: "7" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "pinkDark white--text",
                                      class: { hideBtnGoto: _vm.hideBtnGoto },
                                      attrs: {
                                        "x-large": "",
                                        tile: "",
                                        elevation: "0",
                                        disabled: _vm.isDisable,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.btnActions(
                                            _vm.goTo,
                                            _vm.goBack,
                                            _vm.isNext,
                                            false,
                                            _vm.parentPage
                                          ),
                                            _vm.hitTrack(
                                              _vm.level_2_number,
                                              _vm.click_name,
                                              _vm.click_type
                                            )
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.btnLabel) + " ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }