import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import it from 'vuetify/es5/locale/it';
//css
import '@/assets/scss/main.scss'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                // NB modificare anche le variables di scss
                white: '#ffffff',
                whiteDark: '#f1f1f1',
                AXABlue: '#00008f',
                AXAdeepSapphire: '#00005b',
                AXAdarkIndigo: '#5872B4',
                AXAazure: '#354576',
                black: '#333333',
                blackLight: '#666',
                grayBgDarker: '#dbe0e2',
                grayBgDark: '#e5e5e5',
                grayBgLight: '#f5f5f5',
                gray: '#ccc',
                red: '#ff1721',
                pink: '#EFB9C5',
                pinkDark: '#EE677D',
                blueDark: '#354576',
                blue: '#257DDD',
                lightBlue: '#DFEAF4',
                lightenBlue: '#f6f9fb',
                extraLightBlue: '#eff6fb',
            },
        },
    },
    lang: {
        locales: {it},
        current: 'it',
    },
    icons: {
        iconfont: 'md',
    }
});