<template>
    <v-container fluid class="py-0">
        <v-layout justify-center align-center style="min-height: calc(100vh - 242px)">
            <v-flex>
                <v-row>
                    <v-col cols="12" xl="9" class="pb-0 mx-auto">
                        <v-container class="text-center py-0">
                            <v-layout>
                                <v-flex>
                                    <v-row align="center" justify="center">
                                        <v-col cols="12" class="caption">
                                            <h2 class="display-2">Salva la tua quotazione via email</h2>
                                            <p class="title font-weight-regular mt-4 mb-0 px-lg-10">
                                                Inserisci i tuoi dati per <strong>ricevere il riepilogo della quotazione</strong>
                                            </p>
                                        </v-col>
                                    </v-row>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-col>
                </v-row>
                <!-- form -->
                <v-row class="px-0">
                    <v-container fluid class="pt-0">
                        <v-row>
                            <v-col cols="12" sm="9" lg="6" class="py-0 mx-auto">
                                <v-card class="mx-auto mb-9 text-center v-card--main">
                                    <v-card-title class="gradient-card">
                                        <v-avatar tile size="200" class="mx-auto" v-if="getIdentifier !== 7">
                                            <v-img src="@/assets/images/premio/premio-box-icon.png"/>
                                        </v-avatar>
                                        <v-avatar tile size="200" class="mx-auto" v-if="getIdentifier == 7">
                                            <v-img src="@/assets/images/premio/premio-box-icon-soci.png"/>
                                        </v-avatar>
                                    </v-card-title>
                                    <v-card-text class="py-0 px-3 px-md-12">
                                        <FormSalva/>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-row>
            </v-flex>
        </v-layout>
        <v-row class="px-0">
            <StickyBar
                    :isDisable="isDisable"
                    :goTo="'thanks_mps_salva'"
                    :goBack="token ? '' : 'premio_mps'"
                    :typeButton="'submit'"
                    :btn-label="'Invia'"
                    :chiediConsul="false"
                    :level_2_number="'AXA_PerNoi'"
                    :click_name="'Salva_prospetto_step2'"
                    :click_type="idSession"
            />
        </v-row>
    </v-container>
</template>
<script>
    import {mapState, mapActions, mapGetters, mapMutations} from "vuex";
    import Tooltip from "@/components/partials/Tooltip";
    import FormSalva from "@/components/partials/mps/FormSalva";
    import StickyBar from "@/components/base/mps/StickyBar";

    export default {
        name: "SalvaBody",
        components: {
            Tooltip,
            FormSalva,
            StickyBar
        },
        computed: {
            ...mapGetters({
                'getInsuredData': 'params/getInsuredData',
                'getIdentifier': 'params/getIdentifier',
            }),
        },
        watch: {},
        data: () => ({
            token: this.$route.params.token,
            appuntamento: false,
            valid: false
        }),
        mounted() {
            this.name = this.getInsuredData.name;
        },
        methods: {
            ...mapActions({
                'actionIsFormValidate': 'stickyBar/actionIsFormValidate',
                'actionIsDisable': 'stickyBar/actionIsDisable',
            }),
        },
    };
</script>
