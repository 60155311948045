<template>
    <v-content class="page_thanks" :class="mail == 'salva'? 'save':''">
        <ThanksBody :mail="mail"/>
    </v-content>
</template>

<script>
    import ThanksBody from "@/components/axa/Thanks_body";
    import {mapGetters} from "vuex";

    export default {
        name: "thanks",
        components: {
            ThanksBody,
        },
        computed: {
            ...mapGetters({
                'getQuotationId': 'params/getQuotationId',
                getCustomerTypeTrk: 'params/getCustomerTypeTrk',
            }),
        },
        data() {
            return {
                isNext: this.$route.params.isNext,
                mail: this.$route.params.mail,
                idSession: sessionStorage.getItem('sessionId'),
            };
        },
        beforeMount(){
            window['tc_vars'] = {
                env_template: "AXA_PerNoi",
                env_work: process.env.VUE_APP_TRK_ENV,
                env_channel: channel,
                user_category: this.getCustomerTypeTrk,
                page_name: this.mail == 'salva' ? "Salva_quotazione_typ" : "Chiedi_a_consulente_typ",
                user_id: sessionStorage.getItem('sessionId'),
            };
        },
        head: {
            title: {
                inner: 'Conferma'
            },
            script: function () {
                return [
                    {
                        type: 'text/javascript',
                        src: process.env.VUE_APP_URL_AXA1,
                    },
                    {
                        type: 'text/javascript',
                        inner: 'if (typeof tc_events_global === "function") { \n' +
                               '    tc_events_global(this, "virtual_page", {});\n' +
                               '}\n' +
                               'tc_events_global(this,\'xt_click\',{\'level_2_number\':\'AXA_PerNoi\',\'click_name\':\'' + (this.mail == 'salva' ? 'Salva_quotazione_typ' : 'Chiedi_a_consulente_typ') + '\',\'click_type\':\'' + this.getQuotationId + '\'});'
                    },
                ]
            },
            noscript: [
                {
                    src: '//redirect4708.tagcommander.com/utils/noscript.php?id=1&amp;amp;mode=iframe',
                    width: '1',
                    height: '1',
                    rel: 'noindex,nofollow',
                }
            ],
        },
    };
</script>
