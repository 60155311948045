<template>
  <v-app>
    <Header/>
    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>
    <FooterStep/>
  </v-app>
</template>

<script>
import Header from "@/components/base/mps/Header";
import FooterStep from "@/components/base/mps/Footer-stepbar";
import {ensureFocusVisibility} from "@/utils/utils";

export default {
  name: "masterStepMPS",
  components: {
    Header,
    FooterStep,
  },
  data() {
    return {
      idSession: sessionStorage.getItem('sessionId'),
    };
  },
  mounted() {
    this.onDocumentReady();
  },
  methods: {
    onDocumentReady() {
      console.log("Document is ready");
      ensureFocusVisibility();
    }
  }
};
</script>
