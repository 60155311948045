var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-content",
    { staticClass: "page_thanks", class: _vm.mail == "salva" ? "save" : "" },
    [_c("ThanksBody", { attrs: { mail: _vm.mail } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }