<template>
    <v-container fluid class="py-0">
        <v-layout justify-center align-center style="min-height: calc(100vh - 282px)">
            <v-flex>
                <!-- titolo con dialog -->
                <v-row>
                    <v-col cols="12" xl="9" class="pb-0 mx-auto">
                        <v-container class="text-center py-0">
                            <v-layout>
                                <v-flex>
                                    <v-row align="center" justify="center">
                                        <v-col cols="12" class="caption">
                                            <h2>Chi sono i due assicurati?</h2>
                                            <p class="title font-weight-regular mt-4 mb-0 px-lg-10">
                                                Inserisci i dati delle due persone a cui vorresti <strong>intestare la
                                                polizza</strong>
                                            </p>
                                            <Dialog :items="assicurati"
                                                    :level_2_number="'AXA_PerNoi'"
                                                    :click_name="'Più_info'"
                                                    :click_type="idSession"/>
                                        </v-col>
                                    </v-row>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-col>
                </v-row>
                <!-- form -->
                <v-row class="px-0 fill-height">
                    <v-container fluid class="pt-0">
                        <v-row>
                            <v-col cols="12" sm="9" lg="6" class="py-0 mx-auto">
                                <v-card class="mx-auto mb-9 text-center v-card--main">
                                    <v-card-title class="gradient-card">
                                        <div class="mx-auto imgContainer" v-if="getInsTypeData.identifier !== 7">
                                            <img src="@/assets/images/premio/premio-box-icon_578x578.png" />
                                        </div>
                                        <div class="mx-auto imgContainer" v-else>
                                            <img src="@/assets/images/premio/premio-box-icon-soci_569x569.png" />
                                        </div>
                                    </v-card-title>
                                    <v-card-text class="py-0 px-3 px-md-12">
                                        <Form/>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-row>
            </v-flex>
        </v-layout>
        <!-- sticky bar -->
        <v-row class="px-0">
            <StickyBar
                    :goTo="'premio_axa'"
                    :goBack="'chiAmi_axa'"
                    :typeButton="'submit'"
                    :btn-label="'Prosegui'"
                    :level_2_number="'AXA_PerNoi'"
                    :click_name="'Prosegui_step3'"
                    :click_type="idSession"
            />
        </v-row>
    </v-container>
</template>
<script>
    import {mapState,mapGetters} from "vuex";
    import Dialog from "@/components/partials/Dialog";
    import Form from "@/components/partials/axa/Form";
    import StickyBar from "@/components/base/axa/StickyBar";

    export default {
        name: "assicuratiBody",
        components: {
            Dialog,
            Form,
            StickyBar
        },
        data: () => ({
            idSession: sessionStorage.getItem('sessionId'),
        }),
        computed: {
            ...mapState({
                assicurati: state => state.dialog.assicurati
            }),
            ...mapGetters({
                'getInsTypeData' : 'params/getInsTypeData'
            }),
        },
    };
</script>
