<template>
    <v-content class="page_salva">
        <SalvaBody/>
    </v-content>
</template>

<script>
    import SalvaBody from "@/components/axa/Salva_body";
    import {mapGetters, mapActions} from "vuex";

    export default {
        name: "salva",
        components: {
            SalvaBody,
        },
        data: function () {
            return {
                isNext: this.$route.params.isNext,
                token: this.$route.params.token,
                idSession: sessionStorage.getItem('sessionId'),
                mail: this.$route.params.mail,
            };
        },
        computed: {
            ...mapGetters({
                'isDisable': 'stickyBar/getIsDisable',
                getCustomerTypeTrk: 'params/getCustomerTypeTrk',
            }),
        },
        methods: {
            ...mapActions({
                'actionQuotationParams': 'params/actionQuotationParams'
            }),
        },
        head: {
            title: {
                inner: 'Salva quotazione'
            },
            script: function () {
                return [
                    {
                        type: 'text/javascript',
                        src: process.env.VUE_APP_URL_AXA1,
                    },
                    {
                        type: 'text/javascript',
                        inner: 'if (typeof tc_events_global === "function") { \n' +
                            '    tc_events_global(this, "virtual_page", {});\n' +
                            '}'
                    },
                ]
            },
            noscript: [{
                src: '//redirect4708.tagcommander.com/utils/noscript.php?id=1&amp;amp;mode=iframe',
                width: '1',
                height: '1',
                rel: 'noindex,nofollow',
            }],
        },
        beforeMount() {
            window['tc_vars'] = {
                env_template: "AXA_PerNoi",
                env_work: process.env.VUE_APP_TRK_ENV,
                env_channel: channel,
                user_category: this.getCustomerTypeTrk,
                page_name: "Salva_prospetto_step2",
                user_id: sessionStorage.getItem('sessionId'),
            };
        },
        mounted() {
            if (this.token) {
                let app_retrieve_url = process.env.VUE_APP_RETRIEVE + '/' + this.token;

                axios.get(app_retrieve_url)
                    .then(resp => {
                        this.actionQuotationParams(resp.data.data)
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
            if (this.mail) {
                window.tc_events_global(this.$el, 'xt_click', {
                    'level_2_number': 'AXA_PerNoi',
                    'click_name': 'Salva_prospetto_step1',
                    'click_type': sessionStorage.getItem('sessionId')
                });
            }
        },
    };
</script>