
const state = {
    dummy : {}
};

const getters = {

};

const actions = {

};

const mutations = {

};

export default {
    namespaced : true,
    state,
    mutations,
    getters,
    actions
}

