var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-timeline",
    {
      staticClass: "infografica px-0 px-md-4 pt-0 mt-md-10",
      attrs: { dense: "" },
    },
    _vm._l(_vm.items, function (item) {
      return _c(
        "v-timeline-item",
        {
          key: item.title,
          staticClass: "mb-2 mb-md-5 align-start align-md-center",
          attrs: { large: "" },
          scopedSlots: _vm._u(
            [
              {
                key: "icon",
                fn: function () {
                  return [
                    _c("v-avatar", [
                      _c("img", { attrs: { src: item.image, alt: "" } }),
                    ]),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        [
          item.box
            ? _c(
                "v-row",
                {
                  attrs: {
                    "data-aos": "fade-up",
                    "data-aos-easing": "ease",
                    "data-aos-duration": "500",
                    "data-aos-delay": "400",
                  },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-4 pl-xl-8 mb-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "h2",
                        { staticClass: "headline text-uppercase mb-2 pl-2" },
                        [_vm._v(_vm._s(item.title))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "pl-2",
                          domProps: { innerHTML: _vm._s(item.description) },
                        },
                        [_vm._v(" ")]
                      ),
                      _c(
                        "v-container",
                        { staticClass: "py-0" },
                        [
                          _c(
                            "v-row",
                            _vm._l(item.boxes, function (box, index) {
                              return _c(
                                "v-col",
                                {
                                  key: item.number,
                                  staticClass: "infografica__box mt-4",
                                  attrs: { cols: "12", md: "4" },
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "text-center",
                                      attrs: {
                                        flat: "",
                                        tile: "",
                                        height: "100%",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card-text",
                                        {
                                          staticClass:
                                            "pt-10 pb-8 px-5 px-sm-10 px-md-6",
                                        },
                                        [
                                          _c(
                                            "v-avatar",
                                            {
                                              attrs: {
                                                color: "white AXABlue--text",
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.boxes[index].number
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass: "mb-0",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  item.boxes[index].text
                                                ),
                                              },
                                            },
                                            [_vm._v(" ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-row",
                {
                  attrs: {
                    "data-aos": "fade-up",
                    "data-aos-easing": "ease",
                    "data-aos-duration": "500",
                    "data-aos-delay": "400",
                  },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0 py-lg-4 pl-xl-8",
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "h2",
                        { staticClass: "headline text-uppercase mb-2 pl-2" },
                        [_vm._v(_vm._s(item.title))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "pl-2",
                          domProps: { innerHTML: _vm._s(item.description) },
                        },
                        [_vm._v(" ")]
                      ),
                    ]
                  ),
                ],
                1
              ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }