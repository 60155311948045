import Vue from 'vue'
import Vuex from 'vuex'

//Stores
//import chiami from './modules/chiami'
import cards from './modules/cards'
import dialog from './modules/dialog'
import stepper from './modules/stepper'
import stickyBar from './modules/stickyBar'
import forms from './modules/forms'
import params from './modules/params'
import domande from './modules/faq'
import domandeaxa from './modules/faqaxa'
import domandemps from './modules/faqmps'
import infografica from './modules/infografica'
import tooltip from './modules/tooltip'
import carouseldefault from './modules/carouseldefault'
import premio from './modules/premio'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    //home,
    //chiami,
    cards,
    dialog,
    stepper,
    stickyBar,
    forms,
    params,
    domande,
    domandeaxa,
    domandemps,
    infografica,
    carouseldefault,
    premio,
    tooltip
  },
  state: {

  },
  mutations: {

  },
  actions: {

  },
  plugins: [new VuexPersistence().plugin]
})
