var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-content",
    { staticClass: "page_assicurati" },
    [
      _c("Stepper", {
        staticClass: "stepperBar",
        attrs: { index: _vm.indexStepbar },
      }),
      _c("AssicuratiBody"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }