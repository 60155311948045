<template>
    <v-app>
        <Header/>
        <transition name="fade" mode="out-in">
            <router-view/>
        </transition>
        <FooterMobile/>
    </v-app>
</template>

<script>
    import Header from "@/components/base/mps/Header";
    import FooterMobile from "@/components/base/mps/Footer-mobile";

    export default {
        name: "masterMobileMPS",
        components: {
            Header,
            FooterMobile,
        },
        data() {
            return {
                idSession: sessionStorage.getItem('sessionId'),
            };
        },
    };
</script>
