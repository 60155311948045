<template>
    <div class="stickyContainer d-block d-lg-none" v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }">
        <v-layout
                id="sticky_desktop"
                :class="addFixed ? 'isFixed fade-up' : 'fade-down'"
                elevation="5">
            <!-- overlay -->
            <v-overlay v-model="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <!-- sticky default -->
            <v-card width="100%" class="stickyBar" elevation="5">
                <v-container fluid class="py-0">
                    <v-row align="center" justify="center">
                        <v-col cols="12" md="5" class="text-center py-0 px-2 pt-1 col-md-5 col-12">
                            <v-row align="center" justify="center">
                                <v-col cols="12 py-0">
                                    <div class="stickyContainer__header">
                                        <h3 class="title pr-2">Prezzo totale per voi due</h3>
                                        <p class="pb-2 price"><span>{{ parseFloat(getPriceByValue(getSliderValue)).toFixed(2).replace(".", ",") }} €</span>/mese
                                        </p>
                                    </div>
                                </v-col>

                                <v-col cols="col col-6 pr-1 pt-1">
                                    <!-- button ghost -->
                                    <v-btn x-large tile outlined elevation="0"
                                           @click="tracciamento_salva"
                                           color="AXABlue"
                                           class="white--text btnPremio">
                                        Salva quotazione
                                    </v-btn>
                                </v-col>
                                <v-col cols="col col-6 pl-1 pt-1">
                                    <!-- button 1 -->
                                    <v-btn x-large tile elevation="0" @click="tracciamento"
                                           class="pinkDark white--text btnPremio">
                                        Ti chiamiamo noi
                                    </v-btn>
                                </v-col>

                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-layout>
    </div>
</template>

<script>
    import {mapState, mapGetters, mapActions, mapMutations} from "vuex";

    export default {
        name: "StickyBarPremio",
        data() {
            return {
                idSession: sessionStorage.getItem('sessionId'),
                leadId: sessionStorage.getItem('leadId'),
                slider_length: 0,
                quotation: {},
                intersectionOptions: {
                    root: null,
                    rootMargin: '0px 0px 0px 0px',
                    threshold: [0, 1] // [0.25, 0.75] if you want a 25% offset!
                },
                addFixed: false,
                overlay: false,
            };
        },
        computed: {
            ...mapState({
                priceCapitals: state => state.cards.priceCapitals,
            }),
            ...mapGetters({
                'getSliderValue': 'premio/getSliderValue',
                'getQuotation': 'params/getQuotation',
            }),
        },
        created() {
            this.quotation = this.getQuotation;
            this.setSliderValue(this.getCapitalDefaultValue());
        },
        methods: {
            ...mapActions({
                'actionClearQuotation': 'params/actionClearQuotation',
                'actionInsType': 'params/actionInsType',
                'actionQuotationParams': 'params/actionQuotationParams',
                'actionCustomerType': 'params/actionCustomerType'
            }),
            ...mapMutations({
                'setSliderValue': 'premio/setSliderValue'
            }),
            onWaypoint({going}) {
                if (going === this.$waypointMap.GOING_IN) {
                    this.addFixed = false;
                }
                if (going === this.$waypointMap.GOING_OUT) {
                    this.addFixed = true;
                }
            },
            getPriceByValue(value) {
                return this.quotation.capitals[value].price;
            },
            tracciamento() {
                window.tc_events_global(this.$el, 'xt_click', {
                    'level_2_number': 'AXA_PerNoi',
                    'click_name': 'Chiedi_a_consulente_step1',
                    'click_type': this.getPriceByValue(this.getSliderValue)
                });
                this.$router.push('/axa/appuntamento');
            },
            tracciamento_salva() {
                window.tc_events_global(this, 'xt_click', {
                    'level_2_number': 'AXA_PerNoi',
                    'click_name': 'Salva_prospetto_step1',
                    'click_type': sessionStorage.getItem('sessionId')
                });
                this.$router.push('/axa/salva');
            },
            getCapitalDefaultValue() {
                for (let i = 0; i < this.quotation.capitals.length; i++) {
                    if (this.quotation.capitals[i].capital == this.quotation.selectedCapital)
                        return i;
                }
                return 0;
            },
        },
    }
</script>
