var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "slick",
    { ref: "carousel", attrs: { options: _vm.carouselDefault } },
    _vm._l(_vm.items, function (item) {
      return _c(
        "v-card",
        {
          key: item.text,
          staticClass: "mx-auto",
          attrs: { tile: "", flat: "" },
        },
        [
          _c(
            "v-container",
            { staticClass: "pt-0" },
            [
              _c(
                "v-row",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12", lg: "4" } },
                    [_c("img", { attrs: { src: item.image, alt: "" } })]
                  ),
                  _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                    _c("p", {
                      staticClass:
                        "mt-0 px-4 mt-sm-3 px-md-0 text-center text-md-left headline carouselText",
                      domProps: { innerHTML: _vm._s(item.text) },
                    }),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }