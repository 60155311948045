import Home from '../views/axa/Home';
import ChiAmi from '../views/axa/ChiAmi';
import Assicurati from '../views/axa/Assicurati';
import Premio from '../views/axa/Premio';
import Appuntamento from '../views/axa/Appuntamento';
import Salva from '../views/axa/Salva';
import Thanks from '../views/axa/Thanks';

export default [
    //AXA
    {
        path: '/axa',
        name: 'home_axa',
        meta: {layout: "master-axa"},
        component: Home
    },
    {
        path: '/axa/chi_ami',
        name: 'chiAmi_axa',
        meta: {layout: "master-step-axa"},
        component: ChiAmi
    },
    {
        path: '/axa/parlaci',
        name: 'assicurati_axa',
        meta: {layout: "master-step-axa"},
        component: Assicurati
    },
    {
        path: '/axa/premio',
        name: 'premio_axa',
        meta: {layout: "master-mobile-axa"},
        component: Premio
    },
    {
        path: '/axa/salva',
        name: 'salva_axa',
        meta: {layout: "master-step-axa"},
        component: Salva
    },
    {
        path: '/axa/appuntamento',
        name: 'appuntamento_axa',
        meta: {layout: "master-step-axa"},
        component: Appuntamento
    },
    {
        path: '/axa/recupera/:token',
        name: 'recupera_axa',
        meta: {layout: "master-axa"},
        component: Appuntamento
    },
    {
        path: '/axa/thanks',
        name: 'thanks_axa',
        meta: {layout: "master-axa"},
        component: Thanks
    }
];
