<template>
    <v-menu attach nudge-left="310" nudge-top="85%" :close-on-content-click="closeOnContentClick">
        <template v-slot:activator="{ on }">
            <v-icon class="info_icon pl-2" medium color="AXABlue" v-on="on">info_outline</v-icon>
        </template>

        <v-card tile class="mx-auto tooltip" color="AXAdarkIndigo">
            <v-card-text class="white--text">
                <v-icon color="white" class="float-left">info_outline</v-icon>
                <p>{{ items[0].text }}</p>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
    export default {
        name: "Toolitp",
        props: ['items'],
        data() {
            return {
                closeOnContentClick: true,
            };
        },
    };
</script>
<style scoped>
    .v-icon.v-icon:after {
        background: unset;
    }
</style>
