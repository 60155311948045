var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "py-0", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        {
          staticStyle: { "min-height": "calc(100vh - 242px)" },
          attrs: { "justify-center": "", "align-center": "" },
        },
        [
          _c(
            "v-flex",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0 mx-auto",
                      attrs: { cols: "12", xl: "9" },
                    },
                    [
                      _c(
                        "v-container",
                        { staticClass: "text-center py-0" },
                        [
                          _c(
                            "v-layout",
                            [
                              _c(
                                "v-flex",
                                [
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        align: "center",
                                        justify: "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "caption",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "h2",
                                            { staticClass: "display-2" },
                                            [_vm._v("Ti richiamiamo noi")]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "title font-weight-regular mt-4 mb-0 px-lg-10",
                                            },
                                            [
                                              _vm._v(
                                                " Inserisci i tuoi dati per essere ricontattato da un Agente AXA "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "px-0" },
                [
                  _c(
                    "v-container",
                    { staticClass: "pt-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 mx-auto",
                              attrs: { cols: "12", sm: "9", lg: "6" },
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "mx-auto mb-9 text-center v-card--main",
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "gradient-card" },
                                    [
                                      _vm.getIdentifier !== 7
                                        ? _c(
                                            "v-avatar",
                                            {
                                              staticClass: "mx-auto",
                                              attrs: { tile: "", size: "200" },
                                            },
                                            [
                                              _c("v-img", {
                                                attrs: {
                                                  src: require("@/assets/images/premio/premio-box-icon.png"),
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.getIdentifier == 7
                                        ? _c(
                                            "v-avatar",
                                            {
                                              staticClass: "mx-auto",
                                              attrs: { tile: "", size: "200" },
                                            },
                                            [
                                              _c("v-img", {
                                                attrs: {
                                                  src: require("@/assets/images/premio/premio-box-icon-soci.png"),
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "py-0 px-3 px-md-12" },
                                    [_c("FormAppuntamento")],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "px-0" },
        [
          _c("StickyBar", {
            attrs: {
              isDisable: _vm.isDisable,
              goTo: "thanks_axa",
              goBack: _vm.token ? "" : "premio_axa",
              typeButton: "submit",
              "btn-label": "Invia",
              chiediConsul: false,
              level_2_number: "AXA_PerNoi",
              click_name: "Chiedi_a_consulente_step2",
              click_type: _vm.idSession,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }