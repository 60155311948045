<template>
    <v-timeline dense class="infografica px-0 px-md-4 pt-0 mt-md-10">
        <v-timeline-item v-for="item in items" :key="item.title" large class="mb-2 mb-md-5 align-start align-md-center">
            <template v-slot:icon>
                <v-avatar>
                    <img :src="item.image" alt="">
                </v-avatar>
            </template>
            <v-row v-if="item.box"
                   data-aos="fade-up"
                   data-aos-easing="ease"
                   data-aos-duration="500"
                   data-aos-delay="400">
                <!-- con colonne -->
                <v-col cols="12" class="py-4 pl-xl-8 mb-0">
                    <h2 class="headline text-uppercase mb-2 pl-2">{{item.title}}</h2>
                    <div v-html="item.description" class="pl-2">&nbsp;</div>
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="4" class="infografica__box mt-4" v-for="(box, index) in item.boxes"
                                   :key="item.number">
                                <v-card flat tile class="text-center" height="100%">
                                    <v-card-text class="pt-10 pb-8 px-5 px-sm-10 px-md-6">
                                        <v-avatar color="white AXABlue--text">
                                            <span>{{item.boxes[index].number }}</span>
                                        </v-avatar>
                                        <p class="mb-0" v-html="item.boxes[index].text">&nbsp;</p>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
            <!-- senza colonne -->
            <v-row v-else
                   data-aos="fade-up"
                   data-aos-easing="ease"
                   data-aos-duration="500"
                   data-aos-delay="400">
                <v-col cols="12" class="py-0 py-lg-4 pl-xl-8">
                    <h2 class="headline text-uppercase mb-2 pl-2">{{item.title}}</h2>
                    <div v-html="item.description" class="pl-2">&nbsp;</div>
                </v-col>
            </v-row>
        </v-timeline-item>
    </v-timeline>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "Infografica",
        data() {
            return {
                //
            };
        },
        computed: {
            ...mapState({
                items: state => state.infografica.items,
            }),
        }
    };
</script>
