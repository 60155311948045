var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { align: "center", justify: "center" } },
    [
      _c(
        "v-col",
        {
          staticClass: "text-center text-md-left py-0 pr-md-10",
          attrs: { cols: "12", md: "7" },
        },
        [
          _c("p", { staticClass: "mb-4" }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm.quotation.firstInsName) +
                  ", " +
                  _vm._s(_vm.quotation.secondInsName)
              ),
            ]),
            _c("br", { staticClass: "d-md-none" }),
            _vm._v(" scegliendo un capitale di"),
          ]),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "12" } },
                [
                  _c("p", { staticClass: "mb-0" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          parseInt(_vm.getCapitalByValue(_vm.slider_value))
                            .toFixed(0)
                            .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.")
                        ) + " €"
                      ),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c("v-slider", {
            attrs: {
              height: "20",
              mix: "0",
              max: _vm.slider_length,
              step: "1",
              ticks: "always",
              "tick-size": "4",
              color: "pinkDark",
              "track-color": "pink",
            },
            on: { end: _vm.update_quotation },
            model: {
              value: _vm.slider_value,
              callback: function ($$v) {
                _vm.slider_value = $$v
              },
              expression: "slider_value",
            },
          }),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "text-left py-0 slider-ends-text",
                  attrs: { cols: "6" },
                },
                [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        parseInt(_vm.quotation.capitals[0].capital)
                          .toFixed(0)
                          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.")
                      ) + "€"
                    ),
                  ]),
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-right py-0 slider-ends-text",
                  attrs: { cols: "6" },
                },
                [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        parseInt(
                          _vm.quotation.capitals[
                            _vm.quotation.capitals.length - 1
                          ].capital
                        )
                          .toFixed(0)
                          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.")
                      ) + "€"
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c("p", { staticClass: "my-4" }, [
            _vm._v("garantite alla"),
            _c("br", { staticClass: "d-md-none" }),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.quotation.insTypeLbl))]),
            _c("br", { staticClass: "d-md-none" }),
            _c("br", { staticClass: "d-md-none" }),
            _vm._v(" un "),
            _c("strong", [_vm._v("futuro al sicuro")]),
            _vm._v(" anche "),
            _c("strong", [_vm._v("in caso di grandi imprevisti")]),
            _vm._v(
              " poichè potrete contare su un capitale che vi che vi sosterrà nei momenti di difficoltà."
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-col",
        {
          staticClass: "text-center py-0 border-left d-none d-md-block",
          attrs: { cols: "12", md: "5" },
        },
        [
          _c("h3", { staticClass: "title" }, [
            _vm._v("Prezzo totale per voi due"),
          ]),
          _c("p", { staticClass: "pb-2 price" }, [
            _c("span", [
              _vm._v(
                _vm._s(
                  parseFloat(_vm.getPriceByValue(_vm.slider_value))
                    .toFixed(2)
                    .replace(".", ",")
                ) + " €"
              ),
            ]),
            _vm._v("/mese"),
          ]),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "pinkDark white--text",
                      attrs: { "x-large": "", tile: "", elevation: "0" },
                      on: { click: _vm.tracciamento },
                    },
                    [_vm._v(" Ti chiamiamo noi ")]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: {
                        "x-large": "",
                        tile: "",
                        outlined: "",
                        elevation: "0",
                        color: "AXABlue",
                      },
                      on: { click: _vm.tracciamento_salva },
                    },
                    [_vm._v(" Salva quotazione ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }