var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "py-0", attrs: { fluid: "", "fill-height": "" } },
    [
      _c(
        "v-layout",
        { attrs: { "justify-center": "", "align-center": "" } },
        [
          _c(
            "v-flex",
            [
              _c(
                "v-row",
                {
                  staticClass: "hero-banner",
                  attrs: { align: "center", justify: "center" },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", xl: "9" } },
                    [
                      _c(
                        "v-container",
                        { staticClass: "hero-banner__inner text-center" },
                        [
                          _vm.mail === "appointment"
                            ? _c(
                                "v-row",
                                {
                                  attrs: { align: "center", justify: "center" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12", lg: "7" },
                                    },
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          src: require("@/assets/images/thanks/Thanks.png"),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "caption text-center text-lg-left pr-md-10",
                                      attrs: { cols: "12", lg: "5" },
                                    },
                                    [
                                      _c("h2", { staticClass: "display-2" }, [
                                        _vm._v(
                                          "Grazie! La tua richiesta è stata inviata"
                                        ),
                                      ]),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "title font-weight-regular my-6",
                                        },
                                        [
                                          _vm._v(
                                            " Sarai ricontattato da un operatore di Banca Monte dei Paschi di Siena per fissare un appuntamento in una delle filiali della Banca. "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "pinkDark white--text",
                                          attrs: {
                                            "x-large": "",
                                            tile: "",
                                            elevation: "0",
                                          },
                                          on: { click: _vm.tracciamento },
                                        },
                                        [
                                          _vm._v(
                                            " Calcola una nuova quotazione "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.mail === "salva"
                            ? _c(
                                "v-row",
                                {
                                  attrs: { align: "center", justify: "center" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12", lg: "7" },
                                    },
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          src: require("@/assets/images/thanks/Thanks-quotazione.png"),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "caption text-center text-lg-left pr-md-10",
                                      attrs: { cols: "12", lg: "5" },
                                    },
                                    [
                                      _c("h2", { staticClass: "display-2" }, [
                                        _vm._v(
                                          "Ti abbiamo inviato il riepilogo della quotazione via email:"
                                        ),
                                      ]),
                                      _c("h3", { staticClass: "ellispis" }, [
                                        _vm._v(
                                          " " + _vm._s(_vm.getContactMail) + " "
                                        ),
                                      ]),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "title font-weight-regular my-6",
                                        },
                                        [
                                          _vm._v(
                                            " Se vuoi maggiori dettagli sul prodotto o se vuoi formalizzare un preventivo, lasciaci un tuo contatto telefonico. Sarai richiamato da un operatore di Banca Monte dei Pachi di Siena per fissare un appuntamento presso una delle filiali della Banca. "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "pinkDark white--text",
                                          attrs: {
                                            "x-large": "",
                                            tile: "",
                                            elevation: "0",
                                          },
                                          on: { click: _vm.tracciamento_salva },
                                        },
                                        [_vm._v(" Ricontattami ")]
                                      ),
                                      _c("p", { staticClass: "pt-5" }, [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "linkNuovaQuot",
                                            attrs: { href: "/mps" },
                                          },
                                          [_vm._v("Fai una nuova quotazione")]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }