var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "pb-4", attrs: { cols: "12" } },
        _vm._l(_vm.items, function (item) {
          return _c(
            "v-dialog",
            {
              key: item.title,
              attrs: { scrollable: "", "max-width": "960" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "div",
                          _vm._g(
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.hitTrack(
                                    _vm.level_2_number,
                                    _vm.click_name,
                                    _vm.click_type
                                  )
                                },
                              },
                            },
                            on
                          ),
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "AXABlue--text text-uppercase",
                                attrs: { text: "" },
                              },
                              [_vm._v(_vm._s(item.link))]
                            ),
                            _c(
                              "v-icon",
                              {
                                staticClass: "AXABlue--text rotate180",
                                attrs: { small: "" },
                              },
                              [_vm._v("arrow_back")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                "v-card",
                { staticClass: "px-3 py-5", attrs: { tile: "" } },
                [
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "AXABlue--text text-right",
                          attrs: {
                            "aria-label": "Chiudi",
                            elevation: 0,
                            fab: "",
                            small: "",
                            text: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.dialog = false
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v("clear"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center py-0 py-md-3",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "h2",
                                { staticClass: "mb-3 mb-md-6 display-1 mt-3" },
                                [_vm._v(_vm._s(item.title))]
                              ),
                              _c("p", [_vm._v(_vm._s(item.subtitle))]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._l(item.rows, function (row, index) {
                        return _c(
                          "v-row",
                          { key: item.rows[index].title },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "text-center text-md-left",
                                attrs: { cols: "12" },
                              },
                              [
                                _c("h3", { staticClass: "mb-2" }, [
                                  _vm._v(_vm._s(item.rows[index].title)),
                                ]),
                                _c("p", {
                                  staticClass: "mb-0",
                                  domProps: {
                                    innerHTML: _vm._s(item.rows[index].text),
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("h3", { staticClass: "mb-6" }, [
                                _vm._v("Un esempio"),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        _vm._l(item.examples, function (example, index) {
                          return _c(
                            "v-col",
                            {
                              key: item.image,
                              staticClass: "text-center",
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: item.examples[index].image,
                                  alt: "",
                                },
                              }),
                              _c("p", {
                                staticClass: "mb-4",
                                domProps: {
                                  innerHTML: _vm._s(item.examples[index].text),
                                },
                              }),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }