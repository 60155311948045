<template>
    <v-content class="page_chiAmi">
        <Stepper :index="indexStepbar" class="stepperBar"></Stepper>
        <ChiAmiBody/>
    </v-content>
</template>

<script>
    import ChiAmiBody from "@/components/mps/ChiAmi_body";
    import Stepper from "@/components/partials/Stepper";

    import {mapState, mapGetters, mapMutations, mapActions} from "vuex";

    export default {
        name: "chiAmi",
        components: {
            ChiAmiBody,
            Stepper,
        },
        data() {
            return {
                showStepper: true,
                idSession: sessionStorage.getItem('sessionId'),
            };
        },
        head: {
            title: {
                inner: 'Le persone che ami'
            },
            script: [
                {
                    type: 'text/javascript',
                    src: process.env.VUE_APP_URL_AXA1,
                },
                {
                    type: 'text/javascript',
                    inner: 'if (typeof tc_events_global === "function") { \n' +
                        '    tc_events_global(this, "virtual_page", {});\n' +
                        '}'
                },
            ],
            noscript: [{
                src: '//redirect4708.tagcommander.com/utils/noscript.php?id=1&amp;amp;mode=iframe',
                width: '1',
                height: '1',
                rel: 'noindex,nofollow',
            }],
        },
        beforeMount() {
            window['tc_vars'] = {
                env_template: "AXA_PerNoi",
                env_work: process.env.VUE_APP_TRK_ENV,
                env_channel: channel,
                user_category: this.getCustomerTypeTrk,
                page_name: "Step1",
                user_id: sessionStorage.getItem('sessionId'),
            };
        },
        created() {
            this.setIndex(1);
        },
        computed: {
            ...mapState({
                items: state => state.cards.items,
                chiami: state => state.dialog.chiami
            }),
            ...mapGetters({
                indexStepbar: "stepper/getIndex",
                getCustomerType: 'params/getCustomerType',
                getCustomerTypeTrk: 'params/getCustomerTypeTrk',
            })
        },
        methods: {
            ...mapMutations({
                setIndex: "stepper/setIndex"
            }),
            ...mapActions({
                actionCustomerType: 'params/actionCustomerType',
                actionIndex: "stepper/actionIndex"
            }),
            avanti() {
                this.actionIndex();
            }
        },
        mounted() {
            if (this.getCustomerType == "") {
                grecaptcha.ready(function () {
                    grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, {action: 'login'}).then(function (captchaToken) {

                        let query = {
                            captcha: captchaToken
                        };
                        query.type = 'mps';

                        let url_app_lead = process.env.VUE_APP_LEAD + '/' + sessionStorage.getItem('leadId');

                        axios.post(url_app_lead, query)
                            .then(function (res) {
                            });
                    });
                });
                this.actionCustomerType('mps');
            }
        },
        watch: {
            '$route'(to, from) {
                console.log(to, from)
            }
        },
        updated() {
            window.onhashchange = function () {
                if (window.innerDocClick) {
                    window.innerDocClick = false;
                } else {
                    if (window.location.hash != '#undefined') {
                        goBack();
                    } else {
                        history.pushState("", document.title, window.location.pathname);
                        location.reload();
                    }
                }
            }
        }
    };
</script>
