export default ({
    state: {
        categories: ['Due persone assicurate', 'Beneficiari', 'Garanzie', 'Capitale assicurato', 'Durata e premio', 'Disdetta', 'Accertamento salute', 'Glossario'],
        questions: [
            // categoria 1
            {
                title: 'Perché dovrei sottoscrivere questa polizza?',
                description: "Avverti l’esigenza di proteggere te, un’altra persona ei tuoi cari o la tua azienda dagli imprevisti che possono accadere (prematura scomparsa, malattia grave, perdita di autosufficienza). Questa polizza vita consente di proteggere due persone reciprocamente in un’unica soluzione estremamente semplice e completa.",
                category_array: [
                    {
                        group: 'Due persone assicurate',
                    }
                ]
            },
            {
                title: 'Quali possono essere gli assicurati e perché proprio due persone?',
                description: "Gli assicurati sono due persone fisiche che intendono proteggere un progetto di vita o di affari. Per Noi è una soluzione di protezione dedicata alla coppia, qualunque sia il legame che unisce le due persone.",
                category_array: [
                    {
                        group: 'Due persone assicurate',
                    }
                ]
            },
            {
                title: 'Posso assicurare una sola persona?',
                description: "Per Noi è un prodotto destinato a due assicurati che vogliono proteggersi reciprocamente. La soluzione di protezione dedicata al singolo assicurato è <a href=\"https://tuttalavita.axa.it/?customerType=2\" target='_blank'>Tutta la Vita</a>, un prodotto multigaranzia che consente di proteggere scegliendo uno, due o tutte le prestazioni opzionabili: decesso e, malattia grave, perdita di autosufficienza.",
                category_array: [
                    {
                        group: 'Due persone assicurate',
                    }
                ]
            },
            {
                title: 'Che tipo di legame devo avere con l’altra persona assicurata?',
                description: "Non è richiesto un legame specifico.",
                category_array: [
                    {
                        group: 'Due persone assicurate',
                    }
                ]
            },
            {
                title: 'Ci sono limiti di età per assicurarsi?',
                description: "Età minima alla sottoscrizione 18 anni, massimo 60.",
                category_array: [
                    {
                        group: 'Due persone assicurate',
                    }
                ]
            },
            {
                title: 'Posso essere io contraente della polizza, ma assicurare persone diverse da me?',
                description: "Sì, il contraente può essere terzo soggetto rispetto all’assicurato (Es. caso del genitore che vuole “regalare” una polizza di protezione a due propri figli).",
                category_array: [
                    {
                        group: 'Due persone assicurate',
                    }
                ]
            },
            // categoria 2
            {
                title: 'Si possono proteggere anche altre persone rispetto alle due assicurate? / Chi può essere indicato come beneficiario?',
                description: "per il caso prematura scomparsa gli assicurati possono indicare come beneficiari anche persone oltre i due assicurati (per esempio i propri figli, parenti, amici…) d. In caso di malattia grave e perdita di autosufficienza il beneficiario della prestazione è l’assicurato che per primo subisce l’evento. Nel caso di contratto sottoscritto da persone giuridiche il beneficiario è sempre l’azienda.",
                category_array: [
                    {
                        group: 'Beneficiari',
                    }
                ]
            },
            {
                title: 'Posso indicare una “azienda” come beneficiario della polizza?',
                description: "Sì, Per Noi protegge un’impresa da gravi imprevisti che possano accadere a due figure chiave, come soci e collaboratori, per permettere al business di andare avanti.",
                category_array: [
                    {
                        group: 'Beneficiari',
                    }
                ]
            },
            // categoria 3
            {
                title: 'Quali sono le garanzie incluse in questa polizza?',
                description: "La polizza comprende sempre le 3 garanzie (prematura scomparsa, malattia grave e perdita di autosufficienza) a fronte di un unico premio e capitale assicurato." +
                    "Se invece, desideri assicurarti per una singola garanzia scopri la polizza <a href=\"https://tuttalavita.axa.it/?customerType=2\" target='_blank'>Tutta la Vita</a>.",
                category_array: [
                    {
                        group: 'Garanzie',
                    }
                ]
            },
            {
                title: 'Come posso acquistare questa polizza?',
                description: "Presso le Agenzie AXA.",
                category_array: [
                    {
                        group: 'Garanzie',
                    }
                ]
            },
            // categoria 4
            {
                title: 'Cosa è il capitale assicurato, a quanto può ammontare e cosa copre?',
                description: "Il capitale assicurato è l’importo che viene scelto dal contraente al momento della sottoscrizione della polizza e che verrà erogato dalla Compagnia assicurativa all’accertamento del primo evento decesso, malattia grave e perdita di autosufficienza) che colpisce uno dei due assicurati. " +
                    "Il capitale da assicurare dovrà essere scelto tra i seguenti importi: € 25.000, € 37.500, € 50.000, € 62.500, € 75.000, € 87.500, € 100.000, € 112.500, € 125.000.",
                category_array: [
                    {
                        group: 'Capitale assicurato',
                    }
                ]
            },
            {
                title: 'Posso cambiare l’importo del capitale dopo qualche mese o anni?',
                description: "Sì, ma è necessario chiudere la precedente polizza e aprire un nuovo contratto.",
                category_array: [
                    {
                        group: 'Capitale assicurato',
                    }
                ]
            },
            {
                title: 'Come e a chi viene erogato il capitale assicurato?',
                description: "Il capitale viene erogato:<ul><li>In caso di decesso di uno dei due assicurati ai beneficiari indicati in polizza</li><li>In caso di malattia grave o perdita di autosufficienza l’assicurato colpito dall’evento</li></ul>" +
                    "Dopo aver denunciato l’evento presso la propria filiale oppure direttamente in Compagnia secondo le modalità previste nel set informativo (scaricabile dal sito <a href=\"https://www.axa.it/assicurazione-vita-per-due-persone\" target='_blank'>axa.it</a>).",
                category_array: [
                    {
                        group: 'Capitale assicurato',
                    }
                ]
            },
            {
                title: 'È vero che la copertura si attiva al primo evento che capita a uno dei due assicurati, o ad entrambi? Fare un esempio',
                description: "la prestazione viene erogata al primo evento tra prematura scomparsa, malattia grave e perdita di autosufficienza che colpisce uno dei due assicurati. Nel caso di evento negativo che colpisce congiuntamente gli assicurati viene erogato un capitale per ciascun evento/assicurato. (es.1: assicurati una coppia sposata: la moglie viene colpita da una malattia qualificabile come grave. La Compagnia corrisponde il capitale scelto al momento della sottoscrizione, previa verifica della sussistenza dei requisiti, a beneficio della moglie. La polizza si chiude) (es. 2: due fratelli assicurati vengono coinvolti in un incidente stradale: entrambi acquisiscono lo stato di non autosufficienza. La Compagnia eroga il capitale scelto al momento della sottoscrizione per ciascun fratello. Quindi ciascun fratello riceve un capitale).",
                category_array: [
                    {
                        group: 'Capitale assicurato',
                    }
                ]
            },
            // categoria 5
            {
                title: 'Quanto dura la copertura?',
                description: "Il contratto dura 10 anni e prevede il regolare pagamento del premio secondo le modalità di frazionamento stabilite alla sottoscrizione.",
                category_array: [
                    {
                        group: 'Durata e premio',
                    }
                ]
            },
            {
                title: 'Il premio è fisso o variabile?',
                description: "Il premio è fisso per tutta la durata contrattuale (10 anni).",
                category_array: [
                    {
                        group: 'Durata e premio',
                    }
                ]
            },
            {
                title: 'Il premio indicato nella quotazione online è a persona o per entrambi gli assicurati?',
                description: "Il premio è calcolato per entrambi.",
                category_array: [
                    {
                        group: 'Durata e premio',
                    }
                ]
            },
            {
                title: 'Il premio deve essere pagato mese per mese, o tutto insieme?',
                description: "Il contraente può scegliere la modalità di frazionamento tra annuale (premio corrisposto una volta l’anno) o mensile.",
                category_array: [
                    {
                        group: 'Durata e premio',
                    }
                ]
            },
            // categoria 6
            {
                title: 'Cosa succede se voglio smettere di pagare?',
                description: "In caso di interruzione del pagamento dei premi le coperture assicurative si sospendono. Entro sei mesi dalla scadenza della prima rata di premio non pagata, il Contraente ha diritto di riprendere il versamento dei premi e ripristinare l’assicurazione pagando le rate di premio arretrate aumentate degli interessi legali." +
                    "Trascorsi 6 mesi dalla scadenza, il ripristino può avvenire solo dietro espressa domanda del Contraente ed accettazione scritta di AXA assicurazioni che può richiedere nuovi accertamenti sanitari, il cui costo eventuale è a carico del Contraente, e decidere tenendo conto del loro esito.",
                category_array: [
                    {
                        group: 'Disdetta',
                    }
                ]
            },
            {
                title: 'Se smetto di pagare posso riscattare la somma versata?',
                description: "No, i premi non possono essere rimborsati perché l’assicurazione continuava a produrre i suoi effetti nel periodo di copertura." +
                    "Nella fase che precede la conclusione del contratto, il Contraente può revocare la proposta contrattuale dandone comunicazione alla Compagnia. La Compagnia si impegna a restituire per intero il premio eventualmente corrisposto entro 30 giorni dal ricevimento della comunicazione.",
                category_array: [
                    {
                        group: 'Disdetta',
                    }
                ]
            },
            // categoria 7
            {
                title: 'È vero che basta una autocertificazione per sottoscrivere la polizza?',
                description: "Sì, è sufficiente che gli assicurati dichiarino di essere in buono stato di salute.",
                category_array: [
                    {
                        group: 'Accertamento salute',
                    }
                ]
            },
            // categoria 8
            {
                title: 'Assicurati',
                description: "Persona fisica <strong>sulla cui vita viene stipulato il contratto</strong>, che può coincidere o no con il Contraente e con il Beneficiario. Le prestazioni previste dal contratto sono determinate in funzione dei suoi dati anagrafici e degli <strong>eventi attinenti alla sua vita</strong>.",
                category_array: [
                    {
                        group: 'Glossario',
                    }
                ]
            },
            {
                title: 'Contraente',
                description: "Persona fisica o giuridica, che può coincidere o no con l’Assicurato o il Beneficiario, che <strong>stipula il contratto</strong> di assicurazione e <strong>si impegna al versamento dei premi</strong> alla Compagnia.",
                category_array: [
                    {
                        group: 'Glossario',
                    }
                ]
            },
            {
                title: 'Beneficiario',
                description: "Persona fisica o giuridica designata in polizza dal Contraente, che può coincidere o no con il Contraente stesso e con l’Assicurato, e che <strong>riceve la prestazione prevista dal contratto quando si verifica l’evento assicurato</strong>.",
                category_array: [
                    {
                        group: 'Glossario',
                    }
                ]
            },
            {
                title: 'Premio',
                description: "<strong>Importo</strong> che il contraente si impegna a corrispondere annualmente <strong>secondo un piano di versamenti previsto dal contratto</strong> di assicurazione.",
                category_array: [
                    {
                        group: 'Glossario',
                    }
                ]
            },
            {
                title: 'Capitale assicurato',
                description: "<strong>Importo</strong> pagato <strong>al beneficiario</strong> in occasione dell’evento occorso all’assicurato.",
                category_array: [
                    {
                        group: 'Glossario',
                    }
                ]
            },
            {
                title: 'Compagnia',
                description: "Società autorizzata all’esercizio dell’attività assicurativa, definita alternativamente anche <strong>società o impresa di assicurazione, con la quale il Contraente stipula il contratto</strong> di assicurazione.",
                category_array: [
                    {
                        group: 'Glossario',
                    }
                ]
            },
        ]
    },
})
