var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        {
          ref: "prospetto",
          attrs: { "lazy-validation": "", id: "formSalva" },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "text-left pb-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-container",
                        { staticClass: "pb-0", attrs: { fluid: "" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "pink title text-uppercase py-1 px-2",
                            },
                            [_vm._v("i tuoi dati")]
                          ),
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "12" } }, [
                                _c(
                                  "p",
                                  { staticClass: "caption text-lowercase" },
                                  [_vm._v("*campi obbligatori")]
                                ),
                              ]),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            md: "6",
                                            id: "name",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "scroll-to",
                                                rawName: "v-scroll-to",
                                                value: "#name",
                                                expression: "'#name'",
                                              },
                                            ],
                                            ref: "field1",
                                            attrs: {
                                              label: "Nome*",
                                              rules: _vm.inputRules,
                                              id: "firstNameInput",
                                              required: "",
                                            },
                                            on: { click: _vm.checkPrivacy },
                                            model: {
                                              value: _vm.name,
                                              callback: function ($$v) {
                                                _vm.name = $$v
                                              },
                                              expression: "name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            md: "6",
                                            id: "lastName",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "scroll-to",
                                                rawName: "v-scroll-to",
                                                value: "#lastName",
                                                expression: "'#lastName'",
                                              },
                                            ],
                                            ref: "field2",
                                            attrs: {
                                              label: "Cognome*",
                                              rules: _vm.inputRules,
                                              id: "lastNameInput",
                                              required: "",
                                            },
                                            on: { click: _vm.checkPrivacy },
                                            model: {
                                              value: _vm.lastName,
                                              callback: function ($$v) {
                                                _vm.lastName = $$v
                                              },
                                              expression: "lastName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            md: "6",
                                            id: "telephone",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "scroll-to",
                                                rawName: "v-scroll-to",
                                                value: "#telephone",
                                                expression: "'#telephone'",
                                              },
                                            ],
                                            ref: "field4",
                                            attrs: {
                                              label: "Telefono*",
                                              id: "phoneInput",
                                              type: "tel",
                                              rules: _vm.telephoneRules,
                                              required: "",
                                            },
                                            on: { click: _vm.checkPrivacy },
                                            model: {
                                              value: _vm.telephone,
                                              callback: function ($$v) {
                                                _vm.telephone = $$v
                                              },
                                              expression: "telephone",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            md: "6",
                                            id: "email",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "scroll-to",
                                                rawName: "v-scroll-to",
                                                value: "#email",
                                                expression: "'#email'",
                                              },
                                            ],
                                            ref: "field3",
                                            attrs: {
                                              label: "E-mail*",
                                              id: "emailInput",
                                              rules: _vm.emailRules,
                                              required: "",
                                            },
                                            on: { click: _vm.checkPrivacy },
                                            model: {
                                              value: _vm.email,
                                              callback: function ($$v) {
                                                _vm.email = $$v
                                              },
                                              expression: "email",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            md: "6",
                                            id: "code",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "scroll-to",
                                                rawName: "v-scroll-to",
                                                value: "#cap",
                                                expression: "'#cap'",
                                              },
                                            ],
                                            ref: "field5",
                                            attrs: {
                                              label: "Cap*",
                                              id: "capInput",
                                              rules: _vm.capRules,
                                              required: "",
                                            },
                                            on: { click: _vm.checkPrivacy },
                                            model: {
                                              value: _vm.cap,
                                              callback: function ($$v) {
                                                _vm.cap = $$v
                                              },
                                              expression: "cap",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-5",
                                          attrs: { cols: "12", md: "6" },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "privacy__link pt-5",
                                              attrs: {
                                                href: "/docs/axa/privacy_2023.pdf",
                                                target: "_blank",
                                              },
                                            },
                                            [_vm._v("Informativa privacy")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "dialog",
          attrs: { scrollable: "", persistent: "", "max-width": "960" },
          model: {
            value: _vm.privacyDialog,
            callback: function ($$v) {
              _vm.privacyDialog = $$v
            },
            expression: "privacyDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "py-5", attrs: { tile: "" } },
            [
              _c(
                "v-card-title",
                { staticClass: "px-3 dialog__card__title py-3" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center py-0 py-md-3",
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "h2",
                            { staticClass: "mb-3 mb-md-6 display-2 mt-3" },
                            [_vm._v("Informativa privacy")]
                          ),
                          _c("h3", [_vm._v("Informativa privacy")]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "px-3 dialog__card__text px-8 py-0" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-md-left text_container",
                          attrs: { cols: "12" },
                        },
                        [
                          _vm._v(
                            " La informiamo che i suoi dati personali sono trattati da AXA Assicurazioni S.p.A., titolare del trattamento, per gestire la sua richiesta ed i servizi assicurativi connessi. I dati raccolti potranno, inoltre, essere trattati per attività statistiche interne connesse alla fornitura del servizio erogato (finalità assicurativa)."
                          ),
                          _c("br"),
                          _vm._v(
                            " Per esercitare i Suoi diritti (accedere ai suoi dati personali, farli aggiornare, integrare, rettificare o cancellare, di chiederne la limitazione e di opporsi al loro trattamento, richiedere la portabilità dei dati, revocare i consensi espressi e, per il processo di decisione automatizzata, ottenere l’intervento umano da parte del titolare del trattamento, esprimere la propria opinione e contestare la decisione, ottenere informazioni sui trasferimenti verso paesi che non fanno parte dell’Unione Europea e sul legittimo interesse di AXA, può scrivere a AXA Assicurazioni S.p.A. – c.a. Data Protection Officer – Corso Como 17 - 20154 Milano (MI) - e-mail: "
                          ),
                          _c(
                            "a",
                            { attrs: { href: "mailto:privacy@axa.it" } },
                            [_vm._v("privacy@axa.it")]
                          ),
                          _vm._v(". Per maggiori informazioni, può leggere l’"),
                          _c(
                            "a",
                            {
                              staticClass: "privacy__link",
                              attrs: {
                                href: "/docs/axa/privacy_2023.pdf",
                                target: "_blank",
                              },
                            },
                            [_vm._v("informativa estesa")]
                          ),
                          _vm._v(
                            ". Inoltre, Lei potrà proporre reclamo all'Autorità di controllo in materia di protezione dei dati personali, in Italia il Garante per la protezione dei dati personali: Piazza Venezia n. 11 - 00187 Roma (+39) 06.696771; "
                          ),
                          _c(
                            "a",
                            {
                              attrs: { href: "mailto:protocollo@pec.gpdp.it" },
                            },
                            [_vm._v("protocollo@pec.gpdp.it")]
                          ),
                          _c("br"),
                          _c("br"),
                          _c("span", { staticClass: "red--text" }, [
                            _vm._v(
                              "Attenzione: il servizio è disponibile solo per i maggiorenni"
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-title",
                { staticClass: "mt-5 v-card__footer py-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _c(
                        "div",
                        { staticClass: "dialog__card__text text_container" },
                        [
                          _vm._v(" Per visualizzare l’informativa completa, "),
                          _c(
                            "a",
                            {
                              staticClass: "privacy__link",
                              attrs: {
                                href: "/docs/axa/privacy_2023.pdf",
                                target: "_blank",
                              },
                            },
                            [_vm._v("Clicca qui")]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    { staticClass: "text-center pb-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "pinkDark white--text",
                          attrs: { "x-large": "", tile: "", elevation: "0" },
                          on: {
                            click: function ($event) {
                              ;(_vm.privacyDialog = false), (_vm.privacy = true)
                            },
                          },
                        },
                        [_vm._v(" Ho capito ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }