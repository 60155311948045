var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        attach: "",
        "nudge-left": "310",
        "nudge-top": "85%",
        "close-on-content-click": _vm.closeOnContentClick,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-icon",
                _vm._g(
                  {
                    staticClass: "info_icon pl-2",
                    attrs: { medium: "", color: "AXABlue" },
                  },
                  on
                ),
                [_vm._v("info_outline")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-card",
        {
          staticClass: "mx-auto tooltip",
          attrs: { tile: "", color: "AXAdarkIndigo" },
        },
        [
          _c(
            "v-card-text",
            { staticClass: "white--text" },
            [
              _c(
                "v-icon",
                { staticClass: "float-left", attrs: { color: "white" } },
                [_vm._v("info_outline")]
              ),
              _c("p", [_vm._v(_vm._s(_vm.items[0].text))]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }