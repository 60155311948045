var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "py-0", attrs: { cols: "12" } },
        _vm._l(_vm.items, function (item) {
          return _c(
            "v-dialog",
            {
              key: item.title,
              attrs: { "max-width": "960", scrollable: "", persistent: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "div",
                          _vm._g(
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.hitTrack(
                                    _vm.level_2_number,
                                    _vm.click_name,
                                    _vm.click_type
                                  )
                                },
                              },
                            },
                            on
                          ),
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "AXABlue--text text-none px-0",
                                attrs: { text: "", ripple: false },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v(_vm._s(item.link)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
              model: {
                value: _vm.privacyDialog,
                callback: function ($$v) {
                  _vm.privacyDialog = $$v
                },
                expression: "privacyDialog",
              },
            },
            [
              _c(
                "v-card",
                { attrs: { tile: "" } },
                [
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "AXABlue--text text-right",
                          attrs: { elevation: 0, fab: "", small: "", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.privacyDialog = false
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v("clear"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-title",
                    { staticClass: "py-0 shadow-bottom" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center py-0 py-md-3",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "h2",
                                { staticClass: "mb-3 mb-md-6 display-1" },
                                [_vm._v(_vm._s(item.title))]
                              ),
                              _c("p", [
                                _c("strong", [_vm._v(_vm._s(item.subtitle))]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-card-text", [
                    _c("div", {
                      staticClass: "text-left mt-4",
                      domProps: { innerHTML: _vm._s(item.text) },
                    }),
                  ]),
                  _c(
                    "v-card-actions",
                    { staticClass: "shadow-top" },
                    [
                      _c(
                        "v-container",
                        { staticClass: "py-0" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12 text-center py-0" } },
                                [
                                  _c("p", { staticClass: "mb-0" }, [
                                    _vm._v(
                                      "Per visualizzare l’informativa completa, "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: item.privacyLink,
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v("Clicca qui")]
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12 text-center" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "pinkDark white--text",
                                      attrs: {
                                        "x-large": "",
                                        tile: "",
                                        elevation: "0",
                                        "data-aos": "fade-zoom-in",
                                        "data-aos-easing": "ease",
                                        "data-aos-duration": "500",
                                        "data-aos-delay": "800",
                                      },
                                      on: {
                                        click: function ($event) {
                                          ;(_vm.privacyDialog = false),
                                            (_vm.privacy = true)
                                        },
                                      },
                                    },
                                    [_vm._v(" Ho capito ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }