var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.indexStepbar < 6
    ? _c(
        "v-stepper",
        { staticClass: "bg-transparent mx-auto", attrs: { value: _vm.index } },
        [
          _c(
            "v-stepper-header",
            [
              _c("v-stepper-step", { attrs: { step: "1" } }),
              _c("v-divider"),
              _c("v-stepper-step", { attrs: { step: "2" } }),
              _c("v-divider"),
              _c("v-stepper-step", { attrs: { step: "3" } }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }