const state = {
    indexStepbar : 1,
};

const getters = {
    getIndex : state => {
        return state.indexStepbar;
    }
};

const actions = {
    actionNextStepbar : (context) => {
        context.commit('setIndexGo');
    },
    actionBackStepbar : (context) => {
        context.commit('setIndexBack');
    },
};

const mutations = {
    setIndexGo : (state) => {
        state.indexStepbar += 1;
    },
    setIndexBack : (state) => {
        state.indexStepbar -= 1;
    },
    setIndex : (state, value) => {
        state.indexStepbar = value;
    }
};

export default {
    namespaced : true,
    state,
    mutations,
    getters,
    actions
}
