var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "mb-6", attrs: { cols: "12" } },
        [
          _c(
            "v-expansion-panels",
            {
              attrs: { multiple: "" },
              model: {
                value: _vm.panel,
                callback: function ($$v) {
                  _vm.panel = $$v
                },
                expression: "panel",
              },
            },
            _vm._l(_vm.items, function (item) {
              return _c(
                "v-expansion-panel",
                { key: item.title },
                [
                  _c(
                    "v-expansion-panel-header",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "actions",
                            fn: function () {
                              return [
                                _c("v-icon", { attrs: { color: "AXABlue" } }, [
                                  _vm._v("keyboard_arrow_down"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_vm._v(" " + _vm._s(_vm.title) + " ")]
                  ),
                  _c(
                    "v-expansion-panel-content",
                    _vm._l(item.rows, function (row, index) {
                      return _c(
                        "v-row",
                        { key: item.rows[index].title },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center text-md-left",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "2" } },
                                    [
                                      _c("v-avatar", [
                                        _c("img", {
                                          attrs: {
                                            src: item.rows[index].image,
                                            alt: "",
                                          },
                                        }),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "10" } },
                                    [
                                      _c("h4", {
                                        staticClass: "title",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            item.rows[index].title
                                          ),
                                        },
                                      }),
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            item.rows[index].text
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "v-expansion-panel-header",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showHeaderAccordion,
                          expression: "showHeaderAccordion",
                        },
                      ],
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "actions",
                            fn: function () {
                              return [
                                _c("v-icon", { attrs: { color: "AXABlue" } }, [
                                  _vm._v("keyboard_arrow_down"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_vm._v(" " + _vm._s(_vm.title) + " ")]
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }