var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "waypoint",
          rawName: "v-waypoint",
          value: {
            active: true,
            callback: _vm.onWaypoint,
            options: _vm.intersectionOptions,
          },
          expression:
            "{ active: true, callback: onWaypoint, options: intersectionOptions }",
        },
      ],
      staticClass: "stickyContainer d-block d-lg-none",
    },
    [
      _c(
        "v-layout",
        {
          class: _vm.addFixed ? "isFixed fade-up" : "fade-down",
          attrs: { id: "sticky_desktop", elevation: "5" },
        },
        [
          _c(
            "v-overlay",
            {
              model: {
                value: _vm.overlay,
                callback: function ($$v) {
                  _vm.overlay = $$v
                },
                expression: "overlay",
              },
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", size: "64" },
              }),
            ],
            1
          ),
          _c(
            "v-card",
            {
              staticClass: "stickyBar",
              attrs: { width: "100%", elevation: "5" },
            },
            [
              _c(
                "v-container",
                { staticClass: "py-0", attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center", justify: "center" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "text-center py-0 px-2 pt-1 col-md-5 col-12",
                          attrs: { cols: "12", md: "5" },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "center", justify: "center" } },
                            [
                              _c("v-col", { attrs: { cols: "12 py-0" } }, [
                                _c(
                                  "div",
                                  { staticClass: "stickyContainer__header" },
                                  [
                                    _c("h3", { staticClass: "title pr-2" }, [
                                      _vm._v("Prezzo totale per voi due"),
                                    ]),
                                    _c("p", { staticClass: "pb-2 price" }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            parseFloat(
                                              _vm.getPriceByValue(
                                                _vm.getSliderValue
                                              )
                                            )
                                              .toFixed(2)
                                              .replace(".", ",")
                                          ) + " €"
                                        ),
                                      ]),
                                      _vm._v("/mese "),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c(
                                "v-col",
                                { attrs: { cols: "col col-6 pr-1 pt-1" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "white--text btnPremio",
                                      attrs: {
                                        "x-large": "",
                                        tile: "",
                                        outlined: "",
                                        elevation: "0",
                                        color: "AXABlue",
                                      },
                                      on: { click: _vm.tracciamento_salva },
                                    },
                                    [_vm._v(" Salva quotazione ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "col col-6 pl-1 pt-1" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "pinkDark white--text btnPremio",
                                      attrs: {
                                        "x-large": "",
                                        tile: "",
                                        elevation: "0",
                                      },
                                      on: { click: _vm.tracciamento },
                                    },
                                    [_vm._v(" Ti chiamiamo noi ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }