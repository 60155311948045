export default ({
    state: {
        items: [
            {
                text: "Nostro figlio Tommaso ha solo 15 anni, ma già pensa in grande. Se in futuro dovesse accadere qualcosa di grave ad uno di noi, potremo permettergli di realizzare i suoi progetti più importanti.",
                image: require('@/assets/images/home/carousel/slide-img-04.png'),
            },
            {
                text: "Non abbiamo mai sentito il bisogno di un legame formale, ma da anni oltre alla casa condividiamo tutti gli aspetti della vita. Ora vogliamo rendere i nostri progetti più solidi per affrontare qualsiasi avversità e proteggerci l’un l’altro.",
                image: require('@/assets/images/home/carousel/slide-img-03.png'),
            },
            {
                text: "Mollare tutto per fondare una startup: noi l’abbiamo fatto a 40 anni. È stata una scelta coraggiosa e ora è tempo di proteggere il nostro business, qualora uno di noi due venisse a mancare o fosse colpito da una grave malattia.",
                image: require('@/assets/images/home/carousel/slide-img-02.png'),
            },
            {
                text: "Da quando siamo nate, siamo inseparabili. Se in futuro una di noi dovesse diventare non autosufficiente, vorremmo poter contare su un supporto concreto.",
                image: require('@/assets/images/home/carousel/slide-img-01.png'),
            },
        ],
    },
})
