var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    { attrs: { flat: "", height: "70" } },
    [
      _c(
        "v-toolbar-title",
        [
          _c(
            "router-link",
            { attrs: { to: "/mps" } },
            [
              _c("v-img", {
                attrs: {
                  src: require("@/assets/images/loghi.svg"),
                  width: "204",
                  height: "51",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-spacer"),
      _c("p", { staticClass: "headline mb-0 font-weight-bold AXABlue--text" }, [
        _vm._v("Per Noi"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }