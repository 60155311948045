var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "py-0", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        {
          staticStyle: { "min-height": "calc(100vh - 282px)" },
          attrs: { "justify-center": "", "align-center": "" },
        },
        [
          _c(
            "v-flex",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0 mx-auto",
                      attrs: { cols: "12", xl: "9" },
                    },
                    [
                      _c(
                        "v-container",
                        { staticClass: "text-center py-0" },
                        [
                          _c(
                            "v-layout",
                            [
                              _c(
                                "v-flex",
                                [
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        align: "center",
                                        justify: "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "caption",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c("h2", [
                                            _vm._v(
                                              "Qual è il legame che vi unisce?"
                                            ),
                                          ]),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "title font-weight-regular mt-4 mb-0 px-lg-10",
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v("Affettivo"),
                                              ]),
                                              _vm._v(" o "),
                                              _c("strong", [
                                                _vm._v("professionale:"),
                                              ]),
                                              _vm._v(
                                                " qualunque esso sia, Per Noi vi protegge dai grandi eventi negativi che possono accadere nella vita. "
                                              ),
                                            ]
                                          ),
                                          _c("Dialog", {
                                            attrs: {
                                              items: _vm.chiami,
                                              level_2_number: "AXA_PerNoi",
                                              click_name: "Più_info",
                                              click_type: _vm.idSession,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0 px-0 mx-auto",
                      attrs: { cols: "12", xl: "9" },
                    },
                    [
                      _vm.items.length
                        ? _c(
                            "slick",
                            {
                              ref: "carousel",
                              attrs: {
                                id: "carousel-text",
                                options: _vm.carouselOptions,
                              },
                            },
                            _vm._l(_vm.items, function (item) {
                              return _c(
                                "div",
                                {
                                  key: item.identifier,
                                  staticClass: "mx-auto text-center",
                                  class: {
                                    active: _vm.active_el == item.identifier,
                                  },
                                  attrs: { tabindex: "0" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectedItem(item.identifier)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-avatar",
                                    { attrs: { size: "80" } },
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          src: require("@/assets/images/chi_ami/nuclei-0" +
                                            item.identifier +
                                            ".png"),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("v-hover", {
                                    attrs: { "open-delay": "10" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ hover }) {
                                            return [
                                              _c(
                                                "v-card",
                                                {
                                                  staticClass: "pt-10 mb-2",
                                                  attrs: {
                                                    height: "210",
                                                    elevation: hover ? 4 : 2,
                                                  },
                                                },
                                                [
                                                  _c("v-card-title", {
                                                    staticClass:
                                                      "mt-0 mt-sm-3 mt-md-3 justify-center title font-weight-regular",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        item.name
                                                      ),
                                                    },
                                                  }),
                                                  _c(
                                                    "v-card-actions",
                                                    { staticClass: "py-0" },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "mx-auto",
                                                          attrs: {
                                                            ripple: false,
                                                            fab: "",
                                                            text: "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "i",
                                                            {
                                                              staticClass:
                                                                "material-icons",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "check_circle"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "px-0" },
        [
          _c("StickyBar", {
            attrs: {
              isDisable: _vm.isDisable,
              goTo: "assicurati_axa",
              goBack: "home_axa",
              "btn-label": "Prosegui",
              level_2_number: "AXA_PerNoi",
              click_name: "Prosegui_step1",
              click_type: _vm.idSession,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }