<template>
    <v-container fluid class="py-0">
        <v-layout justify-center align-center style="min-height: calc(100vh - 242px)">
            <v-flex>
                <v-row>
                    <v-col cols="12" xl="9" class="pb-0 mx-auto">
                        <v-container class="text-center py-0">
                            <v-layout>
                                <v-flex>
                                    <v-row align="center" justify="center">
                                        <v-col cols="12" class="caption">
                                            <h2 class="display-2">Ti richiamiamo noi</h2>
                                            <p class="title font-weight-regular mt-4 mb-0 px-lg-10">
                                                Inserisci i tuoi dati per essere ricontattato da un Agente AXA
                                            </p>
                                        </v-col>
                                    </v-row>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-col>
                </v-row>
                <!-- form -->
                <v-row class="px-0">
                    <v-container fluid class="pt-0">
                        <v-row>
                            <v-col cols="12" sm="9" lg="6" class="py-0 mx-auto">
                                <v-card class="mx-auto mb-9 text-center v-card--main">
                                    <v-card-title class="gradient-card">
                                        <v-avatar tile size="200" class="mx-auto" v-if="getIdentifier !== 7">
                                            <v-img src="@/assets/images/premio/premio-box-icon.png"/>
                                        </v-avatar>
                                        <v-avatar tile size="200" class="mx-auto" v-if="getIdentifier == 7">
                                            <v-img src="@/assets/images/premio/premio-box-icon-soci.png"/>
                                        </v-avatar>
                                    </v-card-title>
                                    <v-card-text class="py-0 px-3 px-md-12">
                                        <FormAppuntamento/>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-row>
            </v-flex>
        </v-layout>
        <v-row class="px-0">
            <StickyBar
                    :isDisable="isDisable"
                    :goTo="'thanks_axa'"
                    :goBack="token ? '' : 'premio_axa'"
                    :typeButton="'submit'"
                    :btn-label="'Invia'"
                    :chiediConsul="false"
                    :level_2_number="'AXA_PerNoi'"
                    :click_name="'Chiedi_a_consulente_step2'"
                    :click_type="idSession"
            />
        </v-row>
    </v-container>
</template>
<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
   // import Tooltip from "@/components/partials/Tooltip";
    import FormAppuntamento from "@/components/partials/axa/FormAppuntamento";
    import StickyBar from "@/components/base/axa/StickyBar";

    export default {
        name: "appuntamentoBody",
        components: {
           // Tooltip,
            FormAppuntamento,
            StickyBar
        },
        computed: {
            ...mapGetters({
                'getIdentifier': 'params/getIdentifier',
                'getAppointmentDataAAI': 'params/getAppointmentDataAAI',
            }),
        },
        watch: {},
        data: () => ({
            token: this.$route.params.token,
            appuntamento: false,
            valid: false,
            businessInsType: false,
        }),
        mounted() {
            this.name = this.getInsuredData.name;
            this.businessInsType = this.getIdentifier == 7;
        },
        methods: {
            ...mapActions({
                'actionIsFormValidate': 'stickyBar/actionIsFormValidate',
                'actionIsDisable': 'stickyBar/actionIsDisable',
                'actionAppointmentData': 'params/actionAppointmentData',
                'getInsTypeData' : "params/getInsTypeData",
            }),
            ...mapMutations({
                'setAppointmentDataAAI': 'params/setAppointmentDataAAI',
            }),
        },
    };
</script>
