<template>
    <slick :options="carouselDefault" ref="carousel">
        <v-card tile flat v-for="item in items" :key="item.text" class="mx-auto">
            <v-container class="pt-0">
                <v-row align="center" justify="center">
                    <v-col cols="12" lg="4" class="py-0">
                        <img :src="item.image" alt="">
                    </v-col>
                    <v-col cols="12" md="6">
                        <p class="mt-0 px-4 mt-sm-3 px-md-0 text-center text-md-left headline carouselText"
                           v-html="item.text"></p>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </slick>
</template>

<script>
    import Slick from "vue-slick";
    import {mapState} from "vuex";

    export default {
        name: "CarouselDefault",
        components: {
            Slick
        },
        data() {
            return {
                carouselDefault: {
                    autoplay: false,
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: true,
                    responsive: [
                        {
                            breakpoint: 1904,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 1264,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 960,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                adaptiveHeight: true
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                adaptiveHeight: true
                            }
                        }
                    ]
                }
            };
        },
        computed: {
            ...mapState({
                items: state => state.carouseldefault.items,
            }),
        }
    };
</script>