const state = {
    insuredTypeItems: [],
    projectItems: [],
    capitals: [],
    upselling: [],
    active_el: 0,
    assicurati: [
        {
            id: 1,
            titolo: "Con solo +5€ al mese",
            content: "Assicurare a Luca, Silvia e Isacco <span class='font-weight-bold'>un master di specializzazione.</span>",
            number: "37.000€",
        },
        {
            id: 2,
            titolo: "Con solo +13€ al mese",
            content: "Assicurare a Luca, Silvia e Isacco <span class='font-weight-bold'>un master di specializzazione.</span><br>" +
                "Ridurre al minimo il peso del <span class='font-weight-bold'>mutuo</span> o dell’<span class='font-weight-bold'>affitto</span><br>",
            number: "50.000€",
        },
    ],
    priceCapitals: 0,
    capital: 0,
    activeUpselling: null,
    //priceCard1: 0,
    priceCard2: 0,
    totalCapitalAssCard1: 0,
    totalCapitalAssCard2: 0,
};

const getters = {
    getIdCapitale: state => {
        return state.active_el;
    },
    getUpselling: state => {
        return state.upselling;
    },
    getPriceCapitals: state => {
        return state.priceCapitals;
    },
    getCapital: state => {
        return state.capital;
    },
    getPriceCard2: state => {
        return state.priceCard2;
    },
    getTotCapitalAssCard1: state => {
        return state.totalCapitalAssCard1;
    },
    getTotCapitalAssCard2: state => {
        return state.totalCapitalAssCard2;
    },
    getActiveUpselling: state => {
        return state.activeUpselling;
    }
};

const actions = {

    actionInsuredType: ({commit}) => {

        //let that = this;
        let insured_type = process.env.VUE_APP_INSURED_TYPE;
        window.axios.get(insured_type, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(resp => {
                console.log(resp.data);
                let items = resp.data.data;
                commit('setInsuredType', items);
            })
            .catch(err => {
                console.log(err);
            });

    },
    actionProjects: ({commit, rootGetters}) => {

        //console.log(rootGetters);
        let projects_url = process.env.VUE_APP_PROJECT_TYPE  + rootGetters['params/getInsType'];

        axios.get(projects_url, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(resp => {
            console.log('projects', resp.data.data);
            let items = resp.data.data;
            commit('setProjects', items);
        })
/*        .catch(err => {
            console.log(err);
        });*/

    },
    actionQuotation: ({dispatch, commit, rootGetters}, data) => {

        //let quotation_url = process.env.VUE_APP_QUOTATION + '/' + 1283 + '/' + 79;
        let leadId = sessionStorage.getItem('leadId');
        let quotation_url = process.env.VUE_APP_QUOTATION + '/' + leadId + '/' + rootGetters['params/getQuotationId'];

        //INIT CAPITALS AND USPELLING
        if (data.idUpselling === 0 || data.idUpselling === undefined) {

            axios.get(quotation_url)
            .then(resp => {
                let items = resp.data.data;
                console.log(items);
                console.log(data);
                items.capitals.forEach((item, index) => {
                    if(item.capital == items.selectedCapital) {
                        console.log(item, items);
                        commit('setActiveElem', item.id);
                        dispatch('params/actionIdCapitale', item.id, {root:true});
                        console.log(item, items, rootGetters['cards/getIdCapitale']);
                    }
                });

                //if (data.parentComponent !== 'inGrande') {
                commit('setPriceCapitals', items.price);
                commit('setQuotation', items.capitals);
                //}

                //SAVE CAPITALS AND USPELLING
                commit('setCapital', items.selectedCapital);
                commit('setUpselling', items.upselling);

                //CALCULATE PRICE HEADER UPSELLING
                let priceCard1 =  items.upselling[0].price;
                let priceCard2 =  items.upselling[1].price + priceCard1;

                //CALCULATE PRICE UPSELLING CARD 1
                let capitalAssCard1 = items.upselling[0].capital + parseInt(items.selectedCapital);
                commit('setTotCapitalAssCard1', capitalAssCard1.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1."));

                //CALCULATE PRICE UPSELLING CARD 2
                let capitalAssCard2 = items.upselling[1].capital + capitalAssCard1;

                commit('setTotCapitalAssCard2', capitalAssCard2.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1."));
                commit('setPriceCard2', priceCard2);
            })
            .catch(err => {
                console.log(err);
            });
        } else {

            //SELECT UPSELLING
            axios.post(quotation_url, {
                upselSelec: data.idUpselling,
            })
            .then(resp => {
                let items = resp.data.data;
                dispatch('params/actionQuotationParams', items, {root:true});
                //console.log(resp);
            })
            .catch(err => {
                console.log(err);
            });
        }

    },
    actionActiveUpselling : (context, value) => {
        context.commit('setActiveUpselling', value);
    },
};

const mutations = {
    setInsuredType: (state, items) => {
        state.insuredTypeItems = items
    },
    setProjects: (state, items) => {
        state.projectItems = items
    },
    setQuotation: (state, items) => {
        state.firstIdCapitals = items[0].id;
        state.capitals = items;
        state.active_el = items[0].id;

    },
    setUpselling: (state, items) => {
        //console.log(items);
        state.upselling = items;
    },
    setActiveElem: (state, value) => {
        state.active_el = value;
    },
    setPriceCapitals: (state, value) => {
        state.priceCapitals = value;
    },
    setCapital: (state, value) => {
        state.capital = value;
    },
    setPriceCard2: (state, value) => {
        state.priceCard2 = value;
    },
    setTotCapitalAssCard1: (state, value) => {
        state.totalCapitalAssCard1 = value;
    },
    setTotCapitalAssCard2: (state, value) => {
        state.totalCapitalAssCard2 = value;
    },
    setActiveUpselling: (state, value) => {
        if(value) {
            if(typeof(value.capital) == 'string') {
                value.capital = parseInt(value.capital.replace('.', ''));
            }
            if(typeof(value.price) == 'string') {
                value.price = parseInt(value.price.replace('.', ''));
            }
        }
        state.activeUpselling = value;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
