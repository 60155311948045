var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        {
          ref: "prospetto",
          attrs: { "lazy-validation": "", id: "formSalva" },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "text-left pb-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-container",
                        { staticClass: "pb-0", attrs: { fluid: "" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "pink title text-uppercase py-1 px-2",
                            },
                            [_vm._v("i tuoi dati")]
                          ),
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "12" } }, [
                                _c(
                                  "p",
                                  { staticClass: "caption text-lowercase" },
                                  [_vm._v("*campi obbligatori")]
                                ),
                              ]),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            md: "6",
                                            id: "name",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            ref: "field1",
                                            attrs: {
                                              label: "Nome*",
                                              rules: _vm.inputRules,
                                              id: "firstNameInput",
                                              required: "",
                                            },
                                            on: { click: _vm.checkPrivacy },
                                            model: {
                                              value: _vm.name,
                                              callback: function ($$v) {
                                                _vm.name = $$v
                                              },
                                              expression: "name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            md: "6",
                                            id: "lastName",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            ref: "field2",
                                            attrs: {
                                              label: "Cognome*",
                                              id: "lastNameInput",
                                              rules: _vm.inputRules,
                                              required: "",
                                            },
                                            on: { click: _vm.checkPrivacy },
                                            model: {
                                              value: _vm.lastName,
                                              callback: function ($$v) {
                                                _vm.lastName = $$v
                                              },
                                              expression: "lastName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            md: "6",
                                            id: "email",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            ref: "field3",
                                            attrs: {
                                              label: "E-mail*",
                                              id: "emailInput",
                                              rules: _vm.emailRules,
                                              required: "",
                                            },
                                            on: { click: _vm.checkPrivacy },
                                            model: {
                                              value: _vm.email,
                                              callback: function ($$v) {
                                                _vm.email = $$v
                                              },
                                              expression: "email",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            md: "6",
                                            id: "date",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            ref: "field4",
                                            attrs: {
                                              maxlength: "10",
                                              color: "AXABlue",
                                              label: "Data di nascita*",
                                              placeholder: "GG/MM/AAAA",
                                              rules: _vm.dateRules,
                                              type: "tel",
                                            },
                                            on: {
                                              keyup: function ($event) {
                                                _vm.date = _vm.formatDate(
                                                  _vm.date
                                                )
                                              },
                                              click: _vm.checkPrivacy,
                                            },
                                            model: {
                                              value: _vm.date,
                                              callback: function ($$v) {
                                                _vm.date = $$v
                                              },
                                              expression: "date",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v("` "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "dialog",
          attrs: { scrollable: "", persistent: "", "max-width": "960" },
          model: {
            value: _vm.privacyDialog,
            callback: function ($$v) {
              _vm.privacyDialog = $$v
            },
            expression: "privacyDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "py-5", attrs: { tile: "" } },
            [
              _c(
                "v-card-title",
                { staticClass: "px-3 dialog__card__title py-3" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center py-0 py-md-3",
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "h2",
                            { staticClass: "mb-3 mb-md-6 display-2 mt-3" },
                            [_vm._v("Informativa privacy")]
                          ),
                          _c("h3", [_vm._v("Informativa privacy")]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "px-3 dialog__card__text px-8 py-0" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-md-left text_container",
                          attrs: { cols: "12" },
                        },
                        [
                          _c("strong", [
                            _vm._v(
                              "Gentile Cliente, AXA MPS Assicurazioni Vita S.p.A. e Banca Monte dei Paschi di Siena S.p.A. desiderano fornirLe le seguenti informazioni in merito al trattamento dei Suoi dati personali raccolti per effettuare il preventivo della polizza."
                            ),
                          ]),
                          _c("br"),
                          _c("br"),
                          _c("br"),
                          _c("strong", [
                            _vm._v("Chi decide perché e come trattare i dati?"),
                          ]),
                          _c("br"),
                          _vm._v(
                            " AXA MPS Assicurazioni Vita S.p.A. (di seguito “AXA MPS”), in qualità di Titolare autonomo del trattamento dei dati."
                          ),
                          _c("br"),
                          _vm._v(
                            " Si precisa che, per l’erogazione dei servizi richiesti, la Banca Monte dei Paschi di Siena S.p.A. (di seguito la “Banca”) con sede a Siena in piazza Salimbeni n. 3, è intermediario assicurativo dei prodotti di AXA MPS e opera anch’esso come Titolare autonomo del trattamento per attività di identificazione e successivo contatto, se richiesto da Lei e di identificazione (ovvero verificare se è già cliente)."
                          ),
                          _c("br"),
                          _c("br"),
                          _c("strong", [
                            _vm._v(
                              " Per quali scopi e su quale base i dati sono trattati?"
                            ),
                            _c("br"),
                            _vm._v(
                              " Cosa comporta il rifiuto di fornire i dati? "
                            ),
                          ]),
                          _c("br"),
                          _vm._v(" a) Finalità assicurativa"),
                          _c("br"),
                          _vm._v(
                            " I dati anagrafici e di contatto sono trattati per gestire la Sua richiesta di procedere ad una quotazione e per attività statistiche ad essa correlate."
                          ),
                          _c("br"),
                          _vm._v(
                            " Saranno comunicati alla Banca solamente i suoi dati anagrafici, il recapito telefonico e il codice fiscale per la verifica della qualità di cliente della Banca. Il trattamento dei dati è necessario per dare esecuzione alle misure precontrattuali da Lei richieste, per adempiere obblighi di legge, per legittimo interesse connesso all’attività assicurativa (attività statistica). Senza i dati non potranno essere forniti i servizi assicurativi."
                          ),
                          _c("br"),
                          _c("br"),
                          _vm._v(
                            " b) Finalità di contatto da parte della Banca"
                          ),
                          _c("br"),
                          _vm._v(
                            " Qualora Lei richieda di essere contattato per fissare un appuntamento in una filiale della Banca, la Compagnia provvede a comunicare a quest'ultima i seguenti dati personali a Lei riferiti: nome e cognome, codice fiscale (al fine di verificare se è già nostro cliente), CAP (per cercare la filiale a Lei più vicina nel caso non sia già nostro cliente) e telefono. La Banca non acquisisce alcuna informazione inerente al preventivo della polizza che ha effettuato."
                          ),
                          _c("br"),
                          _vm._v(
                            " Il trattamento dei suddetti dati, da parte della Banca, risulta necessario per dar seguito alla Sua richiesta di essere contattato; al riguardo Le precisiamo che per tale finalità non è richiesto il Suo preventivo consenso dal momento che la base giuridica che ne legittima il trattamento è la necessità di dar corso a Sue specifiche richieste. In tal caso il conferimento dei dati non è obbligatorio ma un eventuale rifiuto a fornirli, anche parzialmente, comporterebbe per la Banca l’impossibilità di fornire il servizio richiesto."
                          ),
                          _c("br"),
                          _c("br"),
                          _c("strong", [
                            _vm._v(" A chi sono comunicati i dati? "),
                          ]),
                          _c("br"),
                          _vm._v(
                            " I dati possono essere comunicati ad alcuni soggetti per i soli scopi sopra indicati:"
                          ),
                          _c("br"),
                          _vm._v(" - Dipendenti e collaboratori;"),
                          _c("br"),
                          _vm._v(
                            " - Società terze che trattano i dati per conto del Titolare; in particolare, la Banca nel caso in cui Lei chieda di essere contattato per fissare un appuntamento in una filiale;"
                          ),
                          _c("br"),
                          _vm._v(
                            " - Soggetti della cosiddetta “catena assicurativa”, cioè soggetti che cooperano nella gestione dei rischi assicurativi, quali società del Gruppo, altri assicuratori, coassicuratori, intermediari assicurativi, legali, periti, medici, autofficine, enti pubblici o associazioni di categoria;"
                          ),
                          _c("br"),
                          _vm._v(
                            " - altri soggetti, tra cui società che gestiscono sistemi di informazioni creditizie e di informazione commerciale per rischi finanziari"
                          ),
                          _c("br"),
                          _vm._v(
                            " In caso di trasferimento dei dati fuori dall’Unione Europea, sono assicurate le garanzie previste dalla vigente normativa."
                          ),
                          _c("br"),
                          _c("i", [
                            _vm._v(
                              "I dati non sono ceduti a terzi, né diffusi."
                            ),
                          ]),
                          _c("br"),
                          _c("br"),
                          _c("strong", [
                            _vm._v(
                              " Quali sono i diritti dell’interessato e come esercitarli? "
                            ),
                          ]),
                          _c("br"),
                          _vm._v(
                            " Per accedere, aggiornare, integrare, rettificare, cancellare i dati, chiederne il blocco, opporsi al trattamento, ottenere la limitazione al trattamento, richiedere la portabilità dei dati e far valere il diritto all’oblio, l’interessato può scrivere a:"
                          ),
                          _c("br"),
                          _vm._v(
                            " AXA MPS Assicurazioni Vita S.p.A. c.a. Data Protection Officer - Corso Como 17 – 20154 Milano (MI) - e-mail: "
                          ),
                          _c(
                            "a",
                            { attrs: { href: "mailto:privacy@axa.it" } },
                            [_vm._v("privacy@axa.it")]
                          ),
                          _vm._v("."),
                          _c("br"),
                          _vm._v(
                            " Banca Monte dei paschi di Siena S.p.A. - Staff DPO e Conformità Privacy, Via A. Moro n. 11/13 - 53100 Siena (fax + 39 0577 296520; e-mail: "
                          ),
                          _c(
                            "a",
                            { attrs: { href: "mailto:privacy@mps.it" } },
                            [_vm._v("privacy@mps.it")]
                          ),
                          _vm._v(")."),
                          _c("br"),
                          _vm._v(
                            " Inoltre, potrà proporre reclamo al Garante Privacy ("
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.garanteprivacy.it/",
                                target: "_blank",
                              },
                            },
                            [_vm._v("https://www.garanteprivacy.it/")]
                          ),
                          _vm._v(")"),
                          _c("br"),
                          _c("br"),
                          _c("strong", [
                            _vm._v(" Da dove provengono i dati trattati? "),
                          ]),
                          _c("br"),
                          _vm._v(
                            " I dati sono raccolti da AXA MPS direttamente presso l’interessato o da terzi, anche mediante la consultazione di banche dati."
                          ),
                          _c("br"),
                          _vm._v(
                            " La Banca acquisisce dalla Compagnia esclusivamente i dati personali sopra indicati necessari per effettuare il contatto."
                          ),
                          _c("br"),
                          _c("br"),
                          _c("strong", [
                            _vm._v(
                              " Per quanto tempo i dati vengono conservati? "
                            ),
                          ]),
                          _c("br"),
                          _vm._v(
                            " I dati vengono conservati da AXA MPS per tutto il tempo necessario alla gestione del rapporto con l’interessato: decorsi 10 anni dalla cessazione del rapporto con il cliente si procede alla cancellazione dei dati."
                          ),
                          _c("br"),
                          _vm._v(
                            " La Banca conserva i dati per il tempo necessario ad effettuare il contatto che viene effettuato entro 60 giorni trascorso tale termine i dati sono cancellati."
                          ),
                          _c("br"),
                          _c("br"),
                          _c("strong", [_vm._v(" Informazioni aggiuntive ")]),
                          _c("br"),
                          _vm._v(
                            " L’informativa completa è disponibile sul sito "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.axa-mps.it/",
                                target: "_blank",
                              },
                            },
                            [_vm._v("www.axa-mps.it")]
                          ),
                          _vm._v(
                            ". Altre informazioni sul trattamento dei dati personali sono disponibili nella sezione Privacy del sito web indicato."
                          ),
                          _c("br"),
                          _vm._v(
                            "La presente informativa è aggiornata a Luglio 2020."
                          ),
                          _c("br"),
                          _c("br"),
                          _c("strong", [
                            _vm._v(" Responsabili della Protezione dei Dati "),
                          ]),
                          _c("br"),
                          _vm._v(
                            " Banca Monte dei Paschi di Siena S.p.A e da AXA MPS Assicurazioni Danni S.p.A, hanno istituito la figura del Responsabile della Protezione dei Dati (o Data Protection Officer – DPO) prevista dalla normativa, affidandogli compiti di informazione e consulenza per il titolare del trattamento in merito agli obblighi in materia di protezione dei dati personali e di verifica dell’attuazione ed applicazione della normativa."
                          ),
                          _c("br"),
                          _vm._v(
                            " I DPO possono essere contattati dagli interessati per tutte le questioni relative al trattamento dei propri dati personali e per l’esercizio dei diritti previsti dal GDPR tramite i seguenti recapiti:"
                          ),
                          _c("br"),
                          _vm._v(
                            " - per AXA MPS Assicurazioni Danni S.p.A. – c.a. Data Protection Officer – Corso Como 17 – 20154 Milano – "
                          ),
                          _c(
                            "a",
                            { attrs: { href: "mailto:privacy@axa.it" } },
                            [_vm._v("privacy@axa.it")]
                          ),
                          _c("br"),
                          _vm._v(
                            " - per Banca Monte dei Paschi di Siena S.p.A. PEC: "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "mailto:responsabileprotezionedeidati@postacert.gruppo.mps.it",
                              },
                            },
                            [
                              _vm._v(
                                "responsabileprotezionedeidati@postacert.gruppo.mps.it"
                              ),
                            ]
                          ),
                          _vm._v("; e mail ordinaria "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "mailto:responsabileprotezionedeidati@mps.it",
                              },
                            },
                            [_vm._v("responsabileprotezionedeidati@mps.it")]
                          ),
                          _c("br"),
                          _c("br"),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-title",
                { staticClass: "mt-5 v-card__footer py-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _c(
                        "div",
                        { staticClass: "dialog__card__text text_container" },
                        [
                          _vm._v(" Per visualizzare l’informativa completa, "),
                          _c(
                            "a",
                            {
                              staticClass: "privacy__link",
                              attrs: {
                                href: "https://www.axa-mps.it/privacy",
                                target: "_blank",
                              },
                            },
                            [_vm._v("Clicca qui")]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    { staticClass: "text-center pb-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "pinkDark white--text",
                          attrs: { "x-large": "", tile: "", elevation: "0" },
                          on: {
                            click: function ($event) {
                              ;(_vm.privacyDialog = false), (_vm.privacy = true)
                            },
                          },
                        },
                        [_vm._v(" HO CAPITO ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }