import Vue from 'vue'
import Router from 'vue-router'
import mpsRoutes from './routes/mps';
import axaRoutes from './routes/axa';

Vue.use(Router);


export default new Router({
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            meta: {layout: "master-default"},
            component: () => import(/* webpackChunkName: "home" */ './views/Home.vue'),
        },
        {
            path: '/accessibilita',
            name: 'accessibilita',
            meta: {layout: "master-default"},
            component: () => import(/* webpackChunkName: "accessibilita" */ './views/Accessibilita.vue'),
        },
        {
            path: '/chi_sei',
            name: 'chi_sei',
            meta: {layout: "master-default"},
            component: () => import(/* webpackChunkName: "chisei" */ './views/ChiSei.vue'),
        },
        ...mpsRoutes,
        ...axaRoutes,
    ]

})

