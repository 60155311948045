import { render, staticRenderFns } from "./MasterStep.vue?vue&type=template&id=0ec20f29"
import script from "./MasterStep.vue?vue&type=script&lang=js"
export * from "./MasterStep.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/bwian/PhpstormProjects/it.axa.pernoi-fe/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0ec20f29')) {
      api.createRecord('0ec20f29', component.options)
    } else {
      api.reload('0ec20f29', component.options)
    }
    module.hot.accept("./MasterStep.vue?vue&type=template&id=0ec20f29", function () {
      api.rerender('0ec20f29', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layout/mps/MasterStep.vue"
export default component.exports