var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-footer",
    { staticClass: "footer-mobile", attrs: { height: "auto", padless: "" } },
    [
      _c(
        "v-container",
        { staticClass: "py-0 footer", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "AXAdarkIndigo whiteDark--text",
                  attrs: { cols: "12" },
                },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "", "pa-0": "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _vm._v(
                                ' Messaggio pubblicitario con finalità promozionale. "Per Noi" è un prodotto di AXA Assicurazioni S.p.A. società del Gruppo Assicurativo AXA. La quotazione è indicativa e non vincolante, non costituisce né sostituisce un preventivo e/o una proposta contrattuale in quanto mancante di alcuni elementi necessari all’assunzione del rischio assicurativo. Per avere maggiori informazioni e un preventivo richiedi subito un appuntamento in agenzia. Prima della sottoscrizione leggere il set informativo disponibile su '
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://www.axa.it",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("www.axa.it")]
                              ),
                              _vm._v(". "),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "AXAazure whiteDark--text",
                  attrs: { cols: "12" },
                },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "", "pa-0": "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _vm._v(
                                " © " +
                                  _vm._s(new Date().getFullYear()) +
                                  " AXA Assicurazioni S.p.A | Partita IVA GRUPPO IVA AXA ITALIA n. 10534960967 - "
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "white--text",
                                  attrs: {
                                    href: "https://www.axa.it/privacy",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("Privacy")]
                              ),
                              _vm._v(" - "),
                              _c(
                                "a",
                                {
                                  staticClass: "white--text",
                                  attrs: {
                                    href: "https://www.axa.it/note-legali",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("Note legali")]
                              ),
                              _vm._v(" - "),
                              _c(
                                "a",
                                {
                                  staticClass: "white--text",
                                  attrs: {
                                    href: "https://www.axa.it/cookies",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("Cookies")]
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "white--text",
                                  attrs: {
                                    to: { path: "/accessibilita" },
                                    target: "_blank",
                                  },
                                },
                                [_vm._v(" Dichiarazione di accessibilità ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }