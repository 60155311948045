var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      staticClass: "codiceFiscale",
      attrs: { "max-width": "600", scrollable: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    staticClass:
                      "dialog__btn transparent text-lowercase blue--text font-weight-medium pa-0 resetPaddingLeft",
                    attrs: { large: "", text: "" },
                  },
                  on
                ),
                [_vm._v(" Non ricordi il tuo Codice Fiscale? ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.codeFiscale,
        callback: function ($$v) {
          _vm.codeFiscale = $$v
        },
        expression: "codeFiscale",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "dialog__card__title" },
            [
              _c(
                "v-container",
                { attrs: { "fill-height": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "", "align-center": "" } },
                    [
                      _c("v-flex", { attrs: { xs9: "", "offset-xs-3": "" } }, [
                        _c("h4", [_vm._v("Il tuo codice fiscale")]),
                      ]),
                      _c(
                        "v-flex",
                        { staticClass: "text-right", attrs: { xs3: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "AXABlue--text text-right",
                              attrs: {
                                elevation: 0,
                                fab: "",
                                small: "",
                                text: "",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.codeFiscale = false
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { dark: "" } }, [
                                _vm._v("clear"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "px-0 pb-0" },
            [
              _c(
                "v-container",
                { staticClass: "py-0" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c(
                          "div",
                          {
                            staticClass: "pt-4 blueDark--text font-weight-bold",
                          },
                          [_vm._v("*Campi obbligatori")]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "codiceFiscale__form white pl-5 py-0",
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "codeFisc",
                              attrs: {
                                "lazy-validation": "",
                                id: "formCodiceFiscale",
                              },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c(
                                "v-container",
                                { staticClass: "py-0" },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "pr-md-6 pb-4",
                                          attrs: { xs12: "", md6: "" },
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Nome*",
                                              rules: _vm.inputRules,
                                              required: "",
                                            },
                                            on: {
                                              input: _vm.generaCodiceFiscale,
                                            },
                                            model: {
                                              value: _vm.nome,
                                              callback: function ($$v) {
                                                _vm.nome = $$v
                                              },
                                              expression: "nome",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "pr-md-6 pb-4",
                                          attrs: { xs12: "", md6: "" },
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Cognome*",
                                              rules: _vm.inputRules,
                                              required: "",
                                            },
                                            on: {
                                              input: _vm.generaCodiceFiscale,
                                            },
                                            model: {
                                              value: _vm.cognome,
                                              callback: function ($$v) {
                                                _vm.cognome = $$v
                                              },
                                              expression: "cognome",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "pr-md-6 pb-4",
                                          attrs: { xs12: "", md6: "" },
                                        },
                                        [
                                          _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "scroll-to",
                                                rawName: "v-scroll-to",
                                                value: "#date",
                                                expression: "'#date'",
                                              },
                                            ],
                                            attrs: {
                                              maxlength: "10",
                                              label: "Data di nascita*",
                                              placeholder: "GG/MM/AAAA",
                                              rules: _vm.dateRules,
                                              value: _vm.data,
                                              type: "tel",
                                            },
                                            on: {
                                              keyup: function ($event) {
                                                _vm.data = _vm.formatDate(
                                                  _vm.data
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.data,
                                              callback: function ($$v) {
                                                _vm.data = $$v
                                              },
                                              expression: "data",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "pr-md-6 pb-4",
                                          attrs: { xs12: "", md6: "" },
                                        },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.comuniIter,
                                              label: "Luogo di nascita",
                                              rules: _vm.inputRules,
                                              filter: _vm.cerca,
                                              "menu-props": { maxHeight: 150 },
                                              "hide-no-data": "",
                                            },
                                            on: {
                                              input: _vm.generaCodiceFiscale,
                                            },
                                            model: {
                                              value: _vm.comuneSel,
                                              callback: function ($$v) {
                                                _vm.comuneSel = $$v
                                              },
                                              expression: "comuneSel",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "pr-md-6",
                                          attrs: { xs12: "", md6: "" },
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.selectItems,
                                              label: "Sesso*",
                                              rules: _vm.inputRules,
                                            },
                                            on: {
                                              input: _vm.generaCodiceFiscale,
                                            },
                                            model: {
                                              value: _vm.sesso,
                                              callback: function ($$v) {
                                                _vm.sesso = $$v
                                              },
                                              expression: "sesso",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass:
                                            "pr-md-6 py-6 text-center",
                                          attrs: { xs12: "" },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "prospetto__invia pinkDark white--text font-weight-medium",
                                              attrs: {
                                                text: "",
                                                height: "48",
                                                width: "420",
                                                disabled: _vm.disabled,
                                              },
                                              on: { click: _vm.invia },
                                            },
                                            [_vm._v(" Invia ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }