var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "py-0", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { "justify-center": "", "align-center": "" } },
        [
          _c(
            "v-flex",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0 mx-auto",
                      attrs: { cols: "12", xl: "9" },
                    },
                    [
                      _c(
                        "v-container",
                        { staticClass: "text-center py-0" },
                        [
                          _c(
                            "v-layout",
                            [
                              _c(
                                "v-flex",
                                [
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        align: "center",
                                        justify: "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "caption mb-5 pt-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "h2",
                                            { staticClass: "display-2" },
                                            [_vm._v("La tua quotazione")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "px-0 fill-height" },
                [
                  _c(
                    "v-container",
                    { staticClass: "pt-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 mx-auto",
                              attrs: {
                                cols: "12",
                                sm: "9",
                                md: "10",
                                lg: "8",
                                xl: "7",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "mx-auto mb-0 text-center v-card--main",
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "gradient-card" },
                                    [
                                      !_vm.businessInsType
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "mx-auto imgContainer",
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("@/assets/images/premio/premio-box-icon_578x578.png"),
                                                },
                                              }),
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass:
                                                "mx-auto imgContainer",
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("@/assets/images/premio/premio-box-icon-soci_569x569.png"),
                                                },
                                              }),
                                            ]
                                          ),
                                    ]
                                  ),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "py-0 px-3 px-sm-12" },
                                    [_c("PremioBox"), _c("PremioAccordion")],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0 mx-auto", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-container",
                        { staticClass: "text-left py-0", attrs: { fluid: "" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "py-0 mb-12 mx-auto text-left",
                                  attrs: {
                                    cols: "12",
                                    sm: "9",
                                    md: "10",
                                    lg: "8",
                                    xl: "7",
                                  },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "AXABlue--text font-weight-bold text-none px-0",
                                      attrs: {
                                        text: "",
                                        ripple: false,
                                        href: "/docs/axa/Per_Noi_Set_Informativo-022020.pdf",
                                        target: "_blank",
                                      },
                                      on: { click: _vm.tracciamento },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "transparent pr-2 v-icon--small",
                                        },
                                        [_vm._v("get_app")]
                                      ),
                                      _vm._v(" SCARICA IL SET INFORMATIVO "),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "px-0 d-block d-lg-block" },
        [_c("StickyBarPremio")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }