import ApiService from './api.service'
import { TokenService } from './storage.service'

/*class AuthenticationError extends Error {
    constructor(errorCode, message) {
        super(message)
        this.name = this.constructor.name
        this.message = messageAuthenticationError
        this.errorCode = errorCode
    }
}*/

const AuthService = {
    /**
     * Authentication get token valid
     *
     * @returns access_token
     * @throws AuthenticationError
     **/
    authentication: function () {

        var token = TokenService.getToken();

        return new Promise(function(resolve, reject) {
            //Set Url Axios globally
            ApiService.init(process.env.VUE_APP_ROOT_URL);

            if (token !== null && token !== undefined) {
                //Set Header Axios globally
                ApiService.setHeader();
                resolve(token);
            } else {

                let data = {
                    grant_type: process.env.VUE_APP_GRANT_TYPE,
                    client_id: process.env.VUE_APP_CLIENT_ID,
                    client_secret: process.env.VUE_APP_CLIENT_SECRET,
                    username: process.env.VUE_APP_USERNAME,
                    password: process.env.VUE_APP_PASSWORD
                };

                //console.log(data);
                axios.post(process.env.VUE_APP_TOKEN,
                    data
                )
                .then(function (res) {
                    //console.log(res);
                    TokenService.saveToken(res.data.access_token);
                    // //Set Url Axios globally
                    // ApiService.init(process.env.VUE_APP_ROOT_URL);
                    //Set Header Axios globally
                    ApiService.setHeader();

                    resolve(res.data.access_token);


                    //TokenService.saveRefreshToken(res.data.refresh_token)

                })
                .catch(error => {
                    reject();
                });

            }
        });



    }

    /**
     * Refresh the access token.
     **/
    //Todo da fare

}

export default AuthService;

export {AuthService}