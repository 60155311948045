const state = {
    items: [
        {
            title: 'Dettagli',
            title_open: 'Leggi di meno',
            rows: [
                {
                    title: "<strong>LE GARANZIE</strong>",
                    text: "<p>Il <strong>capitale scelto</strong> in fase di acquisto dalla polizza, sarà riconosciuto ai beneficiari indicati qualora una <strong>malattia grave, la perdita dell’autosufficienza o il decesso colpissero uno dei due assicurati</strong> indicati.</p>" +
                        "<p>In caso di evento contemporaneo, il capitale riconosciuto ai beneficiari sarà il doppio di quello scelto in fase di acquisto della polizza.</p>",
                    image: require('@/assets/images/premio/details-img-01.png'),
                },
                {
                    title: "<strong>BENEFICIARI E ASSICURATI</strong>",
                    text: "<p>Gli assicurati sono le <strong>due persone,</strong> di età compresa tra i 18 e i 60 anni, che sottoscrivono la polizza. Il capitale viene riconosciuto all’assicurato colpito da malattia grave o perdita dell’autosufficienza. </p>" +
                        "<p>In caso di decesso di uno dei due o di entrambi gli assicurati, il capitale sarà riconosciuto ai beneficiari designati in polizza, che possono essere scelti liberamente, senza alcun vincolo di parentela o di età. </p>" +
                        "<p>In caso invece di <strong>due soci,</strong> gli assicurati sono i due soci/manager dell'azienda di età compresa tra i 18 e i 60 anni. Il capitale scelto viene riconosciuto all'azienda stessa.</p>",
                    image: require('@/assets/images/premio/details-img-02.png'),

                },
                {
                    title: "<strong>IL CONTRATTO</strong>",
                    text: "<p>Il contratto vale 10 anni a partire dal momento dell’acquisto della polizza.</p>" +
                        "<p>Durante questi 10 anni, il prezzo della polizza sarà bloccato e dunque non suscettibile di variazioni.</p>" +
                        "<p>Gli assicurati possono disdire il contratto in qualsiasi momento, senza l’obbligo di pagare alcuna penale.</p>" +
                        "<p>Al termine dei 10 anni, qualora non sia avvenuto nessuno degli eventi coperti dalla polizza, il contratto si chiude automaticamente e nessun capitale  sarà riconosciuto ad assicurati e beneficiari.</p>" +
                        "<p>Il costo della polizza viene calcolato in base all’età delle due persone assicurate. Gli assicurati possono scegliere liberamente se pagare il premio mensilmente o annualmente.</p>",
                    image: require('@/assets/images/premio/details-img-03.png'),

                },
            ]
        }
    ],
    slider_value: 0
};

const getters = {
    getSliderValue : state => {
        return state.slider_value;
    }
};

const mutations = {
    setSliderValue : (state, value)  => {
        state.slider_value = value;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations
}

